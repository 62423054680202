import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import './Login.css'

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const handleEmailInput = (e) => setEmail(e.target.value)

    const handlePasswordInput = (e) => setPassword(e.target.value)

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("The form was submitted with the following data:");

        const user = { email: email, password: password }
        console.log(user);

        navigate('/smsCode');
    }

    return (
            <div className="App1">
                <div className="appAside" />
                <div className="appForm">


                    <div className="formTitle">
                        <p className="formTitleLink"> eDvokat prijava </p>

                    </div>
                    <div className="formCenter">
                        <form className="formFields" onSubmit={handleSubmit}>
                            <div className="formField">
                                <label className="formFieldLabel" htmlFor="email">
                                    E-Mail adresa
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="formFieldInput"
                                    placeholder="Unesite Vaš e-mail"
                                    name="email"
                                    value={email}
                                    onChange={handleEmailInput}
                                />
                            </div>

                            <div className="formField">
                                <label className="formFieldLabel" htmlFor="password">
                                    Lozinka
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    className="formFieldInput"
                                    placeholder="Unesite Vašu lozinku"
                                    name="password"
                                    value={password}
                                onChange={handlePasswordInput}
                                />
                            </div>

                            <div className="formField">
                                <button className="formFieldButton">Prijavi se</button>{" "}
                                <Link to="/resetPassword" className="formFieldLink">
                                    Zaboravili ste lozinku?
                                </Link>
                            </div>
                            <div>
                                <Link to="/uslovi" className="formFieldLink">
                                    Uslovi korišćenja.
                                </Link>
                            </div>
                            <div>

                                <Link to="/pravila" className="formFieldLink">
                                    Pravila o privatnosti.
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
export default Login;


