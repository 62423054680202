import './NewProcedure.css'
//import { Box, Button, Card, CardContent, CircularProgress, Grid, Step, StepLabel, Stepper } from '@mui/material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CircularProgress,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Unstable_Grid2 as Grid,
    Step,
    StepLabel,
    Stepper,
    Switch
} from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Field, Form, Formik, FormikConfig, FormikValues } from 'formik';
import { SelectField } from "formik-stepper";
//import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import React, { useCallback, useState } from 'react';
import { mixed, number, object } from 'yup';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
            fontSize: 15
        },
    },
};
function getStyles(name, personName, theme) {
    return {
        fontSize: 15,
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : 1000,
    };
}
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 13,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(90deg, rgba(87,100,222,1) 0%, rgba(85,179,213,1) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: '#55b3d5',
        //backgroundImage:
        //    'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#5764de',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const sleep = time => new Promise(acc => setTimeout(acc, time))

export default function NewProcedure() {
    const theme = useTheme();

    const [procedure, setProcedure] = useState({
        clients: [{
            clientType: '',
            name: '',
            street: '',
            ptt: '',
            city: '',
            mb: '',
            pib: '',
            jmbg: '',
            namesLegalRepresentative: [{ nameLegalRepresentative: '' }],
            phones: [{ phone: "" }],
            mobilePhones: [{ mobilePhone: "" }],
            emails: [{ email: "" }],
            banks: [{ bank: "" }],
            awardCalculationMethods: [],
            costCalculationMethod: ''
        }],
        procedureType: '',
        procedurePodType: '',
        procedureNumber: '',
        suprotneStrane: true,
        suprotnaStrana: {
            name: '',
            adrress: ''
        },
        svojstvoKlijenta: '',
        kazna: '',
        prvostepeniOrgan: '',
        drugostepeniOrgan: '',
        izborOrgana: '',
        krivicniTezinaDelaType: '',
        vrstaPostupkaType: '',
        opsteRadnjeType: '',
        posebneRadnjeType: ''
    });
    const procedureTypes = [
        { value: '' },
        { value: '0', label: "Krivični postupak" },
        { value: '1', label: "Prekršaj/Privredni prestup" },
        { value: '2', label: "Parnica" },
        { value: '3', label: "Izvršenje" },
        { value: '4', label: "Vanparnica" },
        { value: '5', label: "RGZ" },
        { value: '6', label: "Stečaj/Likvidacija" },
        { value: '7', label: "Upravni postupak" },
        { value: '8', label: "Upravni spor" },
        { value: '9', label: "Upis u registar (koji nije RGZ)" },
        { value: '10', label: "Postupak pred poslodavcem/privrednim društvom" },
        { value: '11', label: "Ustavni" },
        { value: '12', label: "Međunarodni sud/Arbitraža" },
        { value: '13', label: "Domaća arbitraža/medijacija" },
        { value: '14', label: "Ostali postupci" },
        { value: '15', label: "Sastavljanje isprava" },
        { value: '16', label: "Opšti i pojedničani akti društava i organa" },
        { value: '17', label: "Ostale usluge" },
    ]
    const krivicniTypes = [
        { value: '' },
        { value: '0', label: "Okrivljeni" },
        { value: '1', label: "Oštećeni kao tužilac" },
        { value: '2', label: "Oštećeni" },
        { value: '3', label: "Privatni tužilac" },
    ]
    //const krivicniOpsteRadnje = [
    //    { value: '' },
    //    { value: '0', label: "Odbrana okrivljenog u pretkrivičnom/predistražnom/istražnom postupku" },
    //    { value: '1', label: "Odbrana okrivljenog na glavnom pretresu i na sednici veća" },
    //    { value: '2', label: "Sastavljanje podnesaka" },
    //    { value: '3', label: "Razgovor sa okrivljenim koji je lišen slobode" },
    //    { value: '4', label: "Sastavljanje pravnih lekova" },
    //    { value: '5', label: "Zastupanje oštećenog, oštećenog kao tužioca i privatnog tužioca" },
    //]
    const procesnaRadnja = [
        { value: '' },
        { value: '0', label: "Održana procesna radnja" },
        { value: '1', label: "Neodržana procesna radnja" },
    ]
    const glavniPretres = [
        { value: '' },
        { value: '0', label: "Održan glavni pretres ili sednica veća" },
        { value: '1', label: "Neodržan glavni pretres ili sednica veća" },
    ]
    const sastavljanjePodnesaka = [
        { value: '' },
        { value: '0', label: "Obrazloženi podnesak" },
        { value: '1', label: "Neobrazloženi podnesak" },
    ]
    const raszovorSaOkrivljenim = [
        { value: '' },
        { value: '0', label: "Razgovor sa okrivljenim" },
    ]
    const sastavljanjePravnihLekova = [
        { value: '' },
        { value: '0', label: "Sastav žalbi na procesna rešenja i prigovor na optužnicu" },
        { value: '1', label: "Sastav žalbi na presudu, meritorna rešenja i vanredni pravni lekovi" },
    ]
    const zastupaljeOstecenog = [
        { value: '' },
        { value: '0', label: "Zastupanje oštećenog - Održana procesna radnja" },
        { value: '1', label: "Zastupanje oštećenog - Neodržana procesna radnja" },
        { value: '2', label: "Zastupanje oštećenog kao tužioca i privatnog tužioca - Održana procesna radnja" },
        { value: '3', label: "Zastupanje oštećenog kao tužioca i privatnog tužioca - Neodržana procesna radnja" },
    ]
    const prekrsajniPrivredniTypes = [
        { value: '' },
        { value: '0', label: "Okrivljeni" },
        { value: '1', label: "Oštećeni" },
    ]
    const parnicaTypes = [
        { value: '' },
        { value: '0', label: "Tužilac" },
        { value: '1', label: "Tuženi" },
        { value: '2', label: "Umešač na strani tužioca" },
        { value: '3', label: "Umešač na strani tuženog" },
        { value: '4', label: "Tuženi-Protivtužilac" },
        { value: '5', label: "Tužilac-Protivtuženi" },
    ]
    const izvrsenjeTypes = [
        { value: '' },
        { value: '0', label: "Izvršni poverilac" },
        { value: '1', label: "Izvršni dužnik" },
        { value: '2', label: "Treće lice" },
    ]
    const vanparnicaTypes = [
        { value: '' },
        { value: '0', label: "Predlagač" },
        { value: '1', label: "Protivnik predlagača" },
    ]
    const rgzTypes = [
        { value: '' },
        { value: '0', label: "Podnosilac zahteva" },
        { value: '1', label: "Protivnik podnosioca zahteva" },
    ]
    const stecajLikvidacijaTypes = [
        { value: '' },
        { value: '0', label: "Stečajni poverilac" },
        { value: '1', label: "Stečajni dužnik" },
        { value: '2', label: "Likvidacioni poverilac" },
        { value: '3', label: "Likvidacioni dužnik" },
        { value: '4', label: "Razlučni poverilac" },
        { value: '5', label: "Izlučni poverilac" },
    ]
    const updavniPostupakTypes = [
        { value: '' },
        { value: '0', label: "Stranka" },
        { value: '1', label: "Treće lice" },
    ]
    const updavniSporTypes = [
        { value: '' },
        { value: '0', label: "Tužilac" },
        { value: '1', label: "Treće lice" },
    ]
    const upisRegistarNijeRGZTypes = [
        { value: '' },
        { value: '0', label: "Podnosilac zahteva" },
        { value: '1', label: "Protivnik podnosioca zahteva" },
    ]
    const postupakPredPoslodavcemTypes = [
        { value: '' },
        { value: '0', label: "Zaposleni" },
        { value: '1', label: "Privredno društvo" },
    ]
    const ustavniTypes = [
        { value: '' },
        { value: '0', label: "Podnosilac" },
        { value: '1', label: "Državni organ" },
        { value: '2', label: "Treće lice" },
    ]
    const medjunarodniTypes = [
        { value: '' },
        { value: '0', label: "Tužilac" },
        { value: '1', label: "Tuženi" },
        { value: '2', label: "Tuženi-Protivtužilac" },
        { value: '3', label: "Tužilac-Protivtuženi" },
    ]
    const domacaArbitrazaTypes = [
        { value: '' },
        { value: '0', label: "Tužilac" },
        { value: '1', label: "Tuženi" },
        { value: '2', label: "Tuženi-Protivtužilac" },
        { value: '3', label: "Tužilac-Protivtuženi" },
    ]
    const ostaliPostupciTypes = [
        { value: '' },
        { value: '0', label: "Podnosilac" },
        { value: '1', label: "Protivnik podnosioca" },
    ]
    const ostaleUslugeTypes = [
        { value: '' },
        { value: '0', label: "Klijent" },
    ]
    const organi = [
        { value: '' },
        { value: '0', label: "Prvostepeni" },
        { value: '1', label: "Drugostepeni" },
    ]

    const clientTypes = [
        { value: '' },
        { value: "0", label: "Fizičko lice" },
        { value: "1", label: "Pravno lice" },
        { value: "2", label: "Preduzetnik" },
    ];
    const costCalculationMethods = [
        { value: '' },
        { value: "0", label: "Advokatska taksa" },
        { value: "1", label: "Procenat od AT" },
        { value: "2", label: "Sporazum sa klijentom" },
    ];
    const awardCalculationMethods = [
        { value: "0", label: "Advokatska taksa" },
        { value: "1", label: "Procenat od AT" },
        { value: "2", label: "Po satu" },
        { value: "3", label: "Srazmerno uspehu" },
    ];
    const krivicniTezinaDela = [
        { value: '' },
        { value: "0", label: "Do 3 godine zatvora" },
        { value: "1", label: "3 do 5 godina zatvora" },
        { value: "2", label: "5 do 10 godina zatvora" },
        { value: "3", label: "10 do 15 godina zatvora" },
        { value: "4", label: "Preko 15 godina zatvora" },
    ];
    const krivicniOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Odbrana okrivljenog u pretkrivičnom/predistražnom/istražnom postupku" },
        { value: "1", label: "Odbrana okrivljenog na glavnom pretresu i na sednici veća" },
        { value: "2", label: "Sastavljanje podnesaka" },
        { value: "3", label: "Razgovor sa okrivljenim koji je lišen slobode." },
        { value: "4", label: "Sastavljanje pravnih lekova" },
        { value: "5", label: "Zastupanje oštećenog" },
        { value: "6", label: "Zastupanje oštećenog kao tužioca i privatnog tužioca" },
    ]; 
    const krivicniIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Održana procesna radnja" },
        { value: "1", label: "Neodržana procesna radnja" },
    ];
    const krivicniIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održan glavni pretres ili sednica veća" },
        { value: "1", label: "Neodržan glavni pretres ili sednica veća" },
    ];
    const krivicniIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const krivicniIzborPosebnihRadnji3 = [
        { value: '' },
        { value: "0", label: "Obavljeni razgovor" },
    ];
    const krivicniIzborPosebnihRadnji4 = [
        { value: "0", label: "Sastav žalbi na procesna rešenja i prigovor naoptužnicu" },
        { value: "1", label: "Sastav žalbi na presudu, meritorna rešenja i vanredni pravni lekovi" },
    ];
    const krivicniIzborPosebnihRadnji5i6 = [
        { value: '' },
        { value: "0", label: "Održana procesna radnja" },
        { value: "1", label: "Neodržana procesna radnja" },
    ];
    const krivicniIzborPosebnihRadnjiPristupAdvokata = [
        { value: '' },
        { value: "0", label: "Pristup advokata" },
    ];
    const krivicniIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Krivična prijava" },
        { value: "1", label: "Privatna tužba" },
        { value: "2", label: "Zahtev za sprovođenje istrage" },
        { value: "3", label: "Predlog za preduzimanje istražnih radnji" },
        { value: "4", label: "Predlog za zaključenje sporazuma o priznanju krivice" },
        { value: "5", label: "Optužnica" },
        { value: "6", label: "Optužni predlog" },
        { value: "7", label: "Odgovor na optužnicu" },
        { value: "8", label: "Predlog za ukidanje pritvora" },
        { value: "9", label: "Pismena odbrana" },
        { value: "10", label: "Obrazloženi podnesak" },
        { value: "11", label: "Predlog za izvršenje kazne zatvora bez napuštanja prostorija u kojima optuženi stanuje" },
        { value: "12", label: "Molba za uslovni otpust" },
        { value: "13", label: "Tužba za sudsku zaštitu povrede prava osuđenog lica" },
        { value: "14", label: "Zahtev za rehabilitaciju" },
    ];
    const krivicniIzborPosebnihRadnji_2_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const krivicniIzborPosebnihRadnji_4_0 = [
        { value: '' },
        { value: "0", label: "Žalba protiv rešenja o zadržavanju" },
        { value: "1", label: "Žalba protiv rešenja o određenju/produženju pritvora" },
        { value: "2", label: "Žalba protiv rešenja o sprovođenju istrage" },
        { value: "3", label: "Prigovor na optužnicu" },
        { value: "4", label: "Odgovor na žalbu" },
        { value: "5", label: "Žalba oštećenog" },
        { value: "6", label: "Odgovor na zahtev za ponavljanje krivičnog postupka" },
        { value: "7", label: "Molba za pomilovanje" },
    ];
    const krivicniIzborPosebnihRadnji_4_1 = [
        { value: '' },
        { value: "0", label: "Žalba protiv presude" },
        { value: "1", label: "Žalba protiv rešenja kojim se izriče mera bezbednosti ili vaspitna mera" },
        { value: "2", label: "Žalba protiv rešenja o oduzimanju imovinske koristi proistekle iz krivičnog dela" },
        { value: "3", label: "Zahtev za ponavljanje krivičnog postupka" },
        { value: "4", label: "Predlog za podizanje zahteva za zaštitu zakonitosti" },
    ];

    const prekrsajniPrivredniPrestupiPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Privredni i privredni prestup" },
        { value: "1", label: "Prekršajni sud" },
        { value: "2", label: "Policija" },
        { value: "3", label: "Sud časti" },
        { value: "4", label: "Ostali prekršajni postupci" },
    ];
    const prekrsajniPrivredniPrestupiOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Redovni ili vanredni pravni lek" },
        { value: "1", label: "Odgovor na pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na pravni lek u komplikovanim slučajevima" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Prekršajna prijava" },
        { value: "1", label: "Pismena odbrana" },
        { value: "2", label: "Obrazloženi podnesak" },
        { value: "3", label: "Podnesak koji sadrži činjenične navode" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Odbrana na održanom pretresu" },
        { value: "1", label: "Prisustvovanje održanom veštačenju" },
        { value: "2", label: "Prisustvovanje održanom uviđaju" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Odbrana na odloženom pretresu" },
        { value: "1", label: "Prisustvovanje neodržanom veštačenju" },
        { value: "2", label: "Prisustvovanje neodržanom uviđaju" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
        { value: "1", label: "Prigovor" },
        { value: "2", label: "Zahtev za ponavljanje postupka" },
        { value: "3", label: "Zahtev za zaštitu zakonitosti" },
        { value: "4", label: "Zahtev za preispitivanje pravosnažnog rešenja" },
    ];
    const prekrsajniPrivredniPrestupiIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
        { value: "1", label: "Odgovor na prigovor" },
        { value: "2", label: "Odgovor na zahtev za ponavljanje postupka" },
        { value: "3", label: "Odgovor na zahtev za zaštitu zakonitosti" },
    ];

    const parnicniPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const parnicniOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const parnicniIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const parnicniIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const parnicniIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Redovni ili vanredni pravni lek" },
        { value: "1", label: "Odgovor na pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na pravni lek u komplikovanim slučajevima" },
    ];
    const parnicniIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Tužba" },
        { value: "1", label: "Protivtužba" },
        { value: "2", label: "Odgovor na tužbu" },
        { value: "3", label: "Obrazloženi podnesak" },
    ];
    const parnicniIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const parnicniIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
        { value: "1", label: "Pristup na održano veštačenje" },
        { value: "2", label: "Pristup na održani uviđaj" },
    ];
    const parnicniIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
        { value: "1", label: "Pristup na neodržano veštačenje" },
        { value: "2", label: "Pristup na neodržani uviđaj" },
    ];
    const parnicniIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
        { value: "1", label: "Prigovor" },
        { value: "2", label: "Žalba sa alternativnim predlogom za revizijsko odlučivanje" },
        { value: "3", label: "Revizija protiv presude" },
        { value: "4", label: "Revizija protiv rešenja" },
        { value: "5", label: "Vanredna revizija" },
        { value: "6", label: "Predlog za ponavljanje postupka" },
        { value: "7", label: "Zahtev za zaštitu zakonitosti" },
        { value: "8", label: "Predlog za vraćanje u pređašnje stanje" },
    ];
    const parnicniIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
        { value: "1", label: "Odgovor na prigovor" },
        { value: "2", label: "Odgovor na žalbu sa alternativnim predlogom za revizijsko odlučivanje" },
        { value: "3", label: "Odgovor na reviziju protiv presude" },
        { value: "4", label: "Odgovor na reviziju protiv rešenja" },
        { value: "5", label: "Odgovor na vanrednu reviziju" },
        { value: "6", label: "Odgovor na predlog za ponavljanje postupka" },
        { value: "7", label: "Odgovor na zahtev za zaštitu zakonitosti" },
        { value: "8", label: "Odgovor na predlog za vraćanje u pređašnje stanje" },
    ];

    const izvrsniPostupakPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const izvrsniPostupakOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Redovni ili vanredni pravni lek" },
        { value: "1", label: "Odgovor na pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na pravni lek u komplikovanim slučajevima" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Sastav predloga za izvršenje" },
        { value: "1", label: "Sastav predloga za obezbeđenje" },
        { value: "2", label: "Sastav predloga za protivizvršenje" },
        { value: "3", label: "Sastav obrazloženog podneska" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
        { value: "1", label: "Pristup na održano veštačenje" },
        { value: "2", label: "Pristup na održani uviđaj" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
        { value: "1", label: "Pristup na neodržano veštačenje" },
        { value: "2", label: "Pristup na neodržani uviđaj" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
        { value: "1", label: "Prigovor" },
        { value: "2", label: "Predlog za vraćanje u pređašnje stanje" },
    ];
    const izvrsniPostupakIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
        { value: "1", label: "Odgovor na prigovor" },
        { value: "2", label: "Odgovor na predlog za vraćanje u pređašnje stanje" },
    ];

    const vanparnicniPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Uređivanje imovinskih odnos" },
        { value: "1", label: "Ostali predmeti (neprocenjivo)" },
    ];
    const vanparnicniOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const vanparnicniIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const vanparnicniIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const vanparnicniIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Redovni ili vanredni pravni lek" },
        { value: "1", label: "Odgovor na pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na pravni lek u komplikovanim slučajevima" },
    ];
    const vanparnicniIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Sastav predloga za raspravljanje zaostavštine" },
        { value: "1", label: "Sastav predloga za određivanje naknade za eksproprisanu nepokretnost" },
        { value: "2", label: "Sastav predloga za uređenje korišćenja zajedničke stvari" },
        { value: "3", label: "Sastav predloga za deobu zajedničke stvari" },
        { value: "4", label: "Sastav predloga za uređenje međa" },
        { value: "5", label: "Sastav predloga za sudski depozit" },
        { value: "6", label: "Sastav predloga za pokretanje vanparničnog postupka (ostalo)" },
        { value: "7", label: "Sastav obrazloženog podneska" },
    ];
    const vanparnicniIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const vanparnicniIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
        { value: "1", label: "Pristup na održano veštačenje" },
        { value: "2", label: "Pristup na održani uviđaj" },
    ];
    const vanparnicniIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
        { value: "1", label: "Pristup na neodržano veštačenje" },
        { value: "2", label: "Pristup na neodržani uviđaj" },
    ];
    const vanparnicniIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
        { value: "1", label: "Prigovor" },
        { value: "2", label: "Predlog za ponavljanje postupka" },
    ];
    const vanparnicniIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
        { value: "1", label: "Odgovor na prigovor" },
        { value: "2", label: "Odgovor na predlog za ponavljanje postupka" },
    ];

    const RGZPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const RGZOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const RGZIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const RGZIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const RGZIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Redovni ili vanredni pravni lek" },
        { value: "1", label: "Odgovor na pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na pravni lek u komplikovanim slučajevima" },
    ];
    const RGZIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Sastav zahteva za upis nepokretnosti" },
        { value: "1", label: "Sastav zahteva za upis stvarnih prava" },
        { value: "2", label: "Sastav zahteva za upis predbeležbe" },
        { value: "3", label: "Sastav zahteva za upis zabeležbe" },
        { value: "4", label: "Sastav zahteva za provođenje promena na nepokretnostima ili stvarnim pravima na njima" },
        { value: "5", label: "Sastav zahteva za brisanje" },
        { value: "6", label: "Sastav obrazloženog podneska" },
    ];
    const RGZIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const RGZIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
        { value: "1", label: "Pristup na održano veštačenje" },
        { value: "2", label: "Pristup na održani uviđaj" },
    ];
    const RGZIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
        { value: "1", label: "Pristup na neodržano veštačenje" },
        { value: "2", label: "Pristup na neodržano uviđaj" },
    ];
    const RGZIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
    ];
    const RGZIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
    ];

    const stecajniLikvidacioniPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const stecajniLikvidacioniOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Redovni ili vanredni pravni lek" },
        { value: "1", label: "Odgovor na pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na pravni lek u komplikovanim slučajevima" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Sastav prijave potraživanja u stečajnom postupku" },
        { value: "1", label: "Sastav prijave potraživanja u likvidacionom postupku" },
        { value: "2", label: "Sastav izlučnog zahteva" },
        { value: "3", label: "Sastav razlučnog zahteva" },
        { value: "4", label: "Sastav obrazloženog podneska" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
        { value: "1", label: "Pristup na održanu medijaciju" },
        { value: "2", label: "Pristup na održanu sednicu" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
        { value: "1", label: "Pristup na neodržano medijaciju" },
        { value: "2", label: "Pristup na neodržano sednicu" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
    ];
    const stecajniLikvidacioniIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
    ];

    const upravniPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const upravniOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const upravniIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const upravniIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const upravniIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Redovni ili vanredni pravni lek" },
        { value: "1", label: "Odgovor na pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na pravni lek u komplikovanim slučajevima" },
    ];
    const upravniIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Sastav predloga" },
        { value: "1", label: "Sastav zahteva" },
        { value: "2", label: "Sastav prijave" },
        { value: "3", label: "Sastav molbe" },
        { value: "4", label: "Sastav obrazloženog podneska" },
    ];
    const upravniIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const upravniIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
    ];
    const upravniIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
    ];
    const upravniIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
        { value: "1", label: "Prigovor" },
        { value: "2", label: "Predlog za menjanje i poništavanje rešenja" },
        { value: "3", label: "Zahtev za ponavljanje postupka" },
        { value: "4", label: "Zahtev za poništavanje konačnog rešenja" },
        { value: "5", label: "Zahtev za ukidanje rešenja" },
        { value: "6", label: "Zahtev za poništavanje, ukidanje ili menjanje pravosnažnog rešenja na preporuku Zaštitnika građana" },
    ];
    const upravniIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
        { value: "1", label: "Odgovor na prigovor" },
        { value: "2", label: "Odgovor na predlog za menjanje i poništavanje rešenja" },
        { value: "3", label: "Odgovor na zahtev za ponavljanje postupka" },
        { value: "4", label: "Odgovor na zahtev za poništavanje konačnog rešenja" },
        { value: "5", label: "Odgovor na zahtev za ukidanje rešenja" },
        { value: "6", label: "Odgovor na zahtev za poništavanje, ukidanje ili menjanje pravosnažnog rešenja na preporuku Zaštitnika građana" },
    ];

    const upravniSporPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const upravniSporOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const upravniSporIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const upravniSporIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const upravniSporIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Vanredni pravni lek" },
        { value: "1", label: "Odgovor na vanredni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na vanredni lek u komplikovanim slučajevima" },
    ];
    const upravniSporIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Sastav tužbe" },
        { value: "1", label: "Sastav predloga" },
        { value: "2", label: "Sastav zahteva" },
        { value: "3", label: "Sastav prijave" },
        { value: "4", label: "Sastav molbe" },
        { value: "5", label: "Sastav obrazloženog podneska" },
    ];
    const upravniSporIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const upravniSporIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
    ];
    const upravniSporIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
    ];
    const upravniSporIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Zahtev za preispitivanje sudske odluke" },
        { value: "1", label: "Zahtev za ponavljanje postupka" },
    ];
    const upravniSporIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na zahtev za preispitivanje sudske odluke" },
        { value: "1", label: "Odgovor na zahtev za ponavljanje postupka" },
    ];

    const upisuRegistarPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const upisuRegistarOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const upisuRegistarIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const upisuRegistarIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const upisuRegistarIzborPosebnihRadnji2 = [
        { value: "0", label: "Redovni pravni lek" },
        { value: "1", label: "Odgovor na redovni pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na redovni pravni lek u komplikovanim slučajevima" },
    ];
    const upisuRegistarIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Sastav predloga" },
        { value: "1", label: "Sastav zahteva" },
        { value: "2", label: "Sastav prijave" },
        { value: "3", label: "Sastav molbe" },
        { value: "4", label: "Sastav obrazloženog podneska" },
    ];
    const upisuRegistarIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const upisuRegistarIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
    ];
    const upisuRegistarIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
    ];
    const upisuRegistarIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
    ];
    const upisuRegistarIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
    ];

    const postupakPredPoslodavcemPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const postupakPredPoslodavcemOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Redovni pravni lek" },
        { value: "1", label: "Odgovor na redovni pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na redovni pravni lek u komplikovanim slučajevima" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Podnesak kojim se pokreće postupak pred poslodavcem (zaštita prava zaposlenih)" },
        { value: "1", label: "Postupak kojim se pokreće postupak pred privrednim društvom (zakazivanje sednice skupštine društva i dr.)" },
        { value: "2", label: "Obrazloženi podnesak" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Žalba" },
        { value: "1", label: "Prigovor" },
    ];
    const postupakPredPoslodavcemIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na žalbu" },
        { value: "1", label: "Odgovor na prigovor" },
    ];

    const ustavniOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
    ];
    const ustavniIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const ustavniIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const ustavniIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Podnesak kojim se pokreće postupak" },
        { value: "1", label: "Obrazloženi podnesak" },
    ];
    const ustavniIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const ustavniIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
    ];
    const ustavniIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
    ];

    const medjunarodniSudPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const medjunarodniSudOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const medjunarodniSudIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const medjunarodniSudIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const medjunarodniSudIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Pravni lek" },
        { value: "1", label: "Odgovor na redovni pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na redovni pravni lek u komplikovanim slučajevima" },
    ];
    const medjunarodniSudIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Podnesak kojim se pokreće postupak" },
        { value: "1", label: "Obrazloženi podnesak" },
    ];
    const medjunarodniSudIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const medjunarodniSudIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
    ];
    const medjunarodniSudIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
    ];
    const medjunarodniSudIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Redovni pravni lek" },
        { value: "1", label: "Vanredni pravni lek" },
    ];
    const medjunarodniSudIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na redovni pravni lek" },
        { value: "1", label: "Odgovor na vanredni pravni lek" },
    ];

    const domacaArbitrazaPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const domacaArbitrazaOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
    ];
    const domacaArbitrazaIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const domacaArbitrazaIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const domacaArbitrazaIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Podnesak kojim se pokreće postupak" },
        { value: "1", label: "Obrazloženi podnesak" },
    ];
    const domacaArbitrazaIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const domacaArbitrazaIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
    ];
    const domacaArbitrazaIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročišt" },
    ];

    const ostaliPostupciPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti" },
        { value: "1", label: "Neprocenjivi predmeti" },
    ];
    const ostaliPostupciOpsteRadnje = [
        { value: '' },
        { value: "0", label: "Sastavljanje podnesaka" },
        { value: "1", label: "Zastupanje i odbrana" },
        { value: "2", label: "Pravni lekovi" },
    ];
    const ostaliPostupciIzborPosebnihRadnji0 = [
        { value: '' },
        { value: "0", label: "Obrazloženi podnesak" },
        { value: "1", label: "Neobrazloženi podnesak" },
    ];
    const ostaliPostupciIzborPosebnihRadnji1 = [
        { value: '' },
        { value: "0", label: "Održane procesne radnje" },
        { value: "1", label: "Neodržane procesne radnje" },
    ];
    const ostaliPostupciIzborPosebnihRadnji2 = [
        { value: '' },
        { value: "0", label: "Pravni lek" },
        { value: "1", label: "Odgovor na redovni pravni lek u standardnim slučajevima" },
        { value: "2", label: "Odgovor na redovni pravni lek u komplikovanim slučajevima" },
    ];
    const ostaliPostupciIzborPosebnihRadnji_0_0 = [
        { value: '' },
        { value: "0", label: "Podnesak kojim se pokreće postupak" },
        { value: "1", label: "Obrazloženi podnesak" },
    ];
    const ostaliPostupciIzborPosebnihRadnji_0_1 = [
        { value: '' },
        { value: "0", label: "Neobrazloženi podnesak" },
    ];
    const ostaliPostupciIzborPosebnihRadnji_1_0 = [
        { value: '' },
        { value: "0", label: "Pristup na održano ročište" },
    ];
    const ostaliPostupciIzborPosebnihRadnji_1_1 = [
        { value: '' },
        { value: "0", label: "Pristup na neodržano ročište" },
    ];
    const ostaliPostupciIzborPosebnihRadnji_2_0 = [
        { value: '' },
        { value: "0", label: "Redovni pravni lek" },
        { value: "1", label: "Vanredni pravni lek" },
    ];
    const ostaliPostupciIzborPosebnihRadnji_2_1i2 = [
        { value: '' },
        { value: "0", label: "Odgovor na redovni pravni lek" },
        { value: "1", label: "Odgovor na vanredni pravni lek" },
    ];

    const sastavljanjeIspravaPoVrstiPostupka = [
        { value: '' },
        { value: "0", label: "Procenjivi predmeti – procenat od vrednosti imovine" },
        { value: "1", label: "Procenjivi predmeti" },
        { value: "2", label: "Neprocenjivi predmeti" },
    ];
    const sastavljanjeIspravaIzborPosebnihRadnji = [
        { value: '' },
        { value: "0", label: "Ortački ugovor" },
        { value: "1", label: "Ugovor o korišćenju zajedničke stvari" },
        { value: "2", label: "Ugovor o posluzi" },
        { value: "3", label: "Ugovor o trgovinskom zastupanju ili posredovanju" },
        { value: "4", label: "Generalni (opšti) ugovor" },
        { value: "5", label: "Predugovor" },
        { value: "6", label: "Ostali ugovori" },
        { value: "7", label: "Zaveštanje" },
        { value: "8", label: "Punomoćje" },
        { value: "9", label: "Ostale izjave" },
    ];
    const sastavljanjeOpstihPojedinacnihAkataIzborPosebnihRadnji = [
        { value: '' },
        { value: "0", label: "Sastav statuta privrednog subjekta" },
        { value: "1", label: "Sastav osnivačkog akta privrednog subjekta" },
        { value: "2", label: "Sastav ostalih opštih akata privrednog subjekta" },
        { value: "3", label: "Sastav opšteg akta državnog organa" },
        { value: "4", label: "Sastav opšteg akta organa lokalne samouprave" },
        { value: "5", label: "Sastav pojedinačnog akta privrednog subjekata" },
        { value: "6", label: "Sastav pojedinačnog akta državnog organa" },
        { value: "7", label: "Sastav pojedinačnog akta organa lokalne samouprave" },
    ];

    const handleChange = (event, clientIndex) => {
        console.log(procedure)

        const clients = procedure.clients
        clients[clientIndex][event.target.name] = event.target.value

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
        }));
    }

    const handleProcedureCheckedChange = (event) => {
        setProcedure((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked
        }));
    }
    const handleProcedureSuprotneStraneChange = (event) => {
        procedure.suprotnaStrana[event.target.name] = event.target.value;

        setProcedure((prevState) => ({
            ...prevState,
            "suprotnaStrana": procedure.suprotnaStrana
        }));
    }
    const handleProcedureChange = (event) => {
        console.log(procedure)
        const { name, value } = event.target;

        setProcedure((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleAwardCalculationMethodsChange = (e, clientIndex) => {
        const { name, value } = e.target;
        const clients = procedure.clients;

        console.log("ASDASDA", value);

        clients[clientIndex].awardCalculationMethods = typeof value === 'string' ? value.split(',') : value;

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["awardCalculationMethods"]: typeof value === 'string' ? value.split(',') : value
        }));
    };
    const handleNamesLegalRepresentativeChange = (e, index, clientIndex) => {
        const { name, value } = e.target;
        const clients = procedure.clients;
        //const list = [...clients[clientIndex].namesLegalRepresentative];
        clients[clientIndex].namesLegalRepresentative[index][name] = value;
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["namesLegalRepresentative"]: list
        }));
    };
    const handleEmailChange = (e, index, clientIndex) => {
        const { name, value } = e.target;
        const clients = procedure.clients;
        //const list = [...clients.emails];
        clients[clientIndex].emails[index][name] = value;
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["emails"]: list
        }));
    };
    const handlePhoneChange = (e, index, clientIndex) => {
        const { name, value } = e.target;
        const clients = procedure.clients;
        //const list = [...clients.phones];
        clients[clientIndex].phones[index][name] = value;
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["phones"]: list
        }));
    };
    const handleMobilePhoneChange = (e, index, clientIndex) => {
        const { name, value } = e.target;
        const clients = procedure.clients;
        //const list = [...clients.mobilePhones];
        clients[clientIndex].mobilePhones[index][name] = value;
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["mobilePhones"]: list
        }));
    };
    const handleBankChange = (e, index, clientIndex) => {
        const { name, value } = e.target;
        const clients = procedure.clients;
        //const list = [...clients.banks];
        clients[clientIndex].banks[index][name] = value;
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["banks"]: list
        }));
    };

    const handleNamesLegalRepresentativeRemove = (index, clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.namesLegalRepresentative];
        clients[clientIndex].namesLegalRepresentative.splice(index, 1);
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["namesLegalRepresentative"]: list
        }));
    };

    const handleNamesLegalRepresentativeAdd = (clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.namesLegalRepresentative, { nameLegalRepresentative: '' }];

        clients[clientIndex].namesLegalRepresentative = [...clients[clientIndex].namesLegalRepresentative, { nameLegalRepresentative: '' }]

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["namesLegalRepresentative"]: list
        }));
    };

    const handleMobilePhoneRemove = (index, clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.mobilePhones];
        clients[clientIndex].mobilePhones.splice(index, 1);
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["mobilePhones"]: list
        }));
    };

    const handleMobilePhoneAdd = (clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.mobilePhones, { mobilePhone: '' }];

        clients[clientIndex].mobilePhones = [...clients[clientIndex].mobilePhones, { mobilePhone: '' }];

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["mobilePhones"]: list
        }));
    };

    const handlePhoneRemove = (index, clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.phones];
        clients[clientIndex].phones.splice(index, 1);

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["phones"]: list
        }));
    };

    const handlePhoneAdd = (clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.phones, { phone: '' }];

        console.log(clients[clientIndex])
        clients[clientIndex].phones = [...clients[clientIndex].phones, { phone: '' }];

        console.log(clients[clientIndex].phones)

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["phones"]: list
        }));
    };

    const handleEmailRemove = (index, clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.emails];
        clients[clientIndex].emails.splice(index, 1);
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["emails"]: list
        }));
    };

    const handleEmailAdd = (clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.emails, { email: '' }];

        clients[clientIndex].emails = [...clients[clientIndex].emails, { email: '' }];

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["emails"]: list
        }));
    };

    const handleBankRemove = (index, clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.banks];
        clients[clientIndex].banks.splice(index, 1);
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["banks"]: list
        }));
    };

    const handleBankAdd = (clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.banks, { bank: '' }];

        clients[clientIndex].banks = [...clients[clientIndex].banks, { bank: '' }];

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["banks"]: list
        }));
    };

    const handleRemoveClient = (clientIndex) => {
        const clients = procedure.clients;
        //const list = [...clients.banks];
        clients.splice(clientIndex, 1);
        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
            //["banks"]: list
        }));
    }
    const handleAddClient = () => {
        const clients = [...procedure.clients, {
            clientType: '',
            name: '',
            street: '',
            ptt: '',
            city: '',
            mb: '',
            pib: '',
            jmbg: '',
            namesLegalRepresentative: [{ nameLegalRepresentative: '' }],
            phones: [{ phone: "" }],
            mobilePhones: [{ mobilePhone: "" }],
            emails: [{ email: "" }],
            banks: [{ bank: "" }],
            awardCalculationMethods: [],
            costCalculationMethod: ''
        }]

        setProcedure((prevState) => ({
            ...prevState,
            "clients": clients
        }));
    };
    return (
        <Card>
            <CardContent className="newProcedure">
                <FormikStepper
                    initialValues={{
                        clientType: "",

                    }}
                    onSubmit={async values => {
                        await sleep(3000)
                        console.log("values", values)
                    }}
                >
                    <FormikStep label="Klijent">
                        <Grid>
                            {procedure.clients.map((client, clientIndex) => (
                                <Grid paddingBottom={2}
                                    key={clientIndex}>
                                    <h2>Klijent {clientIndex + 1}</h2>
                                    {procedure.clients.length === 1 ? null : <CardActions sx={{ justifyContent: 'right' }}>
                                        <button className="formFieldButtonAddClient"
                                            onClick={() => handleRemoveClient(clientIndex)}
                                            type="submit">
                                            X
                                        </button>
                                    </CardActions>}
                                    <Box paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            label="Vrsta klijenta"
                                            type="search"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    fontWeight: 1000,
                                                }
                                            }}
                                            name="clientType"
                                            onChange={(e) => handleChange(e, clientIndex)}
                                            required
                                            select
                                            SelectProps={{ native: true }}
                                            value={client.clientType}
                                            inputProps={{
                                                style: { fontSize: 15 }
                                            }}
                                        >
                                            {clientTypes.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Box>
                                    {client.clientType === '' ? null : <Box paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            type="search"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    fontWeight: 1000,
                                                }
                                            }}
                                            label={`${client.clientType === "0" ? "Ime i prezime klijenta" : "Naziv klijenta"}`}
                                            name="name"
                                            onChange={(e) => handleChange(e, clientIndex)}
                                            required
                                            value={client.name}
                                            inputProps={{
                                                style: { fontSize: 15 }
                                            }}
                                        />
                                    </Box>}
                                    {client.clientType === '' ? null : <Box paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            type="search"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    fontWeight: 1000,
                                                }
                                            }}
                                            label={`${client.clientType === "0" ? "Ulica i broj prebivališta klijenta" : "Ulica i broj sedišta klijenta"}`}
                                            name="street"
                                            onChange={(e) => handleChange(e, clientIndex)}
                                            required
                                            value={client.street}
                                            inputProps={{
                                                style: { fontSize: 15 }
                                            }}
                                        />
                                    </Box>}
                                    {client.clientType === '' ? null : <Box paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            type="search"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    fontWeight: 1000,
                                                }
                                            }}
                                            label={`${client.clientType === "0" ? "Poštanski broj prebivališta klijenta" : "Poštanski broj sedišta klijenta"}`}
                                            name="ptt"
                                            onChange={(e) => handleChange(e, clientIndex)}
                                            required
                                            value={client.ptt}
                                            inputProps={{
                                                style: { fontSize: 15 }
                                            }}
                                        />
                                    </Box>}
                                    {client.clientType === '' ? null : <Box paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            type="search"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    fontWeight: 1000,
                                                }
                                            }}
                                            label={`${client.clientType === "0" ? "Mesto prebivališta klijenta" : "Mesto sedišta klijenta"}`}
                                            name="city"
                                            onChange={(e) => handleChange(e, clientIndex)}
                                            required
                                            value={client.city}
                                            inputProps={{
                                                style: { fontSize: 15 }
                                            }}
                                        />
                                    </Box>}
                                    {client.clientType === '' || client.clientType === '0' ? null : <Box paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            type="search"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    fontWeight: 1000,
                                                }
                                            }}
                                            label="Matični broj klijenta"
                                            name="mb"
                                            onChange={(e) => handleChange(e, clientIndex)}
                                            required
                                            value={client.mb}
                                            inputProps={{
                                                style: { fontSize: 15 }
                                            }}
                                        />
                                    </Box>}
                                    {client.clientType === '' || client.clientType === '0' ? null : <Box paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            type="search"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    fontWeight: 1000,
                                                }
                                            }}
                                            label="Pib klijenta"
                                            name="pib"
                                            onChange={(e) => handleChange(e, clientIndex)}
                                            required
                                            value={client.pib}
                                            inputProps={{
                                                style: { fontSize: 15 }
                                            }}
                                        />
                                    </Box>}
                                    {client.clientType !== '1' ? null : <Grid paddingBottom={2}>
                                        {client.namesLegalRepresentative.map((singleNameLegalRepresentative, index) => (
                                            <div key={index} className="services">
                                                <div className="first-division">
                                                    <Grid
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label="Ime i prezime zakonskog zastupnika klijenta i njegova funkcija"
                                                            type="search"
                                                            InputLabelProps={{
                                                                sx: {
                                                                    fontSize: "14px",
                                                                    fontWeight: 1000,
                                                                }
                                                            }}
                                                            name="nameLegalRepresentative"
                                                            onChange={(e) => handleNamesLegalRepresentativeChange(e, index, clientIndex)}
                                                            required
                                                            value={singleNameLegalRepresentative.nameLegalRepresentative}
                                                            inputProps={{
                                                                style: { fontSize: 15 }
                                                            }}
                                                        />
                                                    </Grid>
                                                </div>
                                                {client.namesLegalRepresentative.length !== 1 && (
                                                    <CardActions sx={{ justifyContent: 'center' }}>
                                                        <button onClick={() => handleNamesLegalRepresentativeRemove(index, clientIndex)}
                                                            className="remove-btn"
                                                        >
                                                            Obriši
                                                        </button>
                                                    </CardActions>
                                                )}
                                            </div>
                                        ))}
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <button onClick={() => handleNamesLegalRepresentativeAdd(clientIndex)}
                                                className="add-btn"
                                            >
                                                Dodaj
                                            </button>
                                        </CardActions>
                                    </Grid>}
                                    {client.clientType === '' ? null : <Grid paddingBottom={2}>
                                        {client.banks.map((singleBank, index) => (
                                            <div key={index} className="services">
                                                <div className="first-division">
                                                    <Grid paddingBottom={2}
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label="Račun klijenta u banci i naziv banke"
                                                            type="search"
                                                            InputLabelProps={{
                                                                sx: {
                                                                    fontSize: "14px",
                                                                    fontWeight: 1000,
                                                                }
                                                            }}
                                                            name="bank"
                                                            onChange={(e) => handleBankChange(e, index, clientIndex)}
                                                            required
                                                            value={singleBank.bank}
                                                            inputProps={{
                                                                style: { fontSize: 15 }
                                                            }}
                                                        />
                                                    </Grid>
                                                </div>
                                                {client.banks.length !== 1 && (
                                                    <CardActions sx={{ justifyContent: 'center' }}>
                                                        <button onClick={() => handleBankRemove(index, clientIndex)}
                                                            className="remove-btn"
                                                        >
                                                            Obriši
                                                        </button>
                                                    </CardActions>
                                                )}
                                            </div>
                                        ))}
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <button onClick={() => handleBankAdd(clientIndex)}
                                                className="add-btn"
                                            >
                                                Dodaj
                                            </button>
                                        </CardActions>
                                    </Grid>}
                                    {client.clientType === '' ? null : <Grid paddingBottom={2}>
                                        {client.phones.map((singlePhone, index) => (
                                            <div key={index} className="services">
                                                <div className="first-division">
                                                    <Grid paddingBottom={2}
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label="Broj fiksnog telefona"
                                                            type="search"
                                                            InputLabelProps={{
                                                                sx: {
                                                                    fontSize: "14px",
                                                                    fontWeight: 1000,
                                                                }
                                                            }}
                                                            name="phone"
                                                            onChange={(e) => handlePhoneChange(e, index, clientIndex)}
                                                            required
                                                            value={singlePhone.phone}
                                                            inputProps={{
                                                                style: { fontSize: 15 }
                                                            }}
                                                        />
                                                    </Grid>
                                                </div>
                                                {client.phones.length !== 1 && (
                                                    <CardActions sx={{ justifyContent: 'center' }}>
                                                        <button onClick={() => handlePhoneRemove(index, clientIndex)}
                                                            className="remove-btn"
                                                        >
                                                            Obriši
                                                        </button>
                                                    </CardActions>
                                                )}
                                            </div>
                                        ))}
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <button onClick={() => handlePhoneAdd(clientIndex)}
                                                className="add-btn"
                                            >
                                                Dodaj
                                            </button>
                                        </CardActions>
                                    </Grid>}
                                    {client.clientType === '' ? null : <Grid paddingBottom={2}>
                                        {client.mobilePhones.map((singleMobilePhone, index) => (
                                            <div key={index} className="services">
                                                <div className="first-division">
                                                    <Grid paddingBottom={2}
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label="Broj mobilnog telefona"
                                                            type="search"
                                                            InputLabelProps={{
                                                                sx: {
                                                                    fontSize: "14px",
                                                                    fontWeight: 1000,
                                                                }
                                                            }}
                                                            name="mobilePhone"
                                                            onChange={(e) => handleMobilePhoneChange(e, index, clientIndex)}
                                                            required
                                                            value={singleMobilePhone.mobilePhone}
                                                            inputProps={{
                                                                style: { fontSize: 15 }
                                                            }}
                                                        />
                                                    </Grid>
                                                </div>
                                                {client.mobilePhones.length !== 1 && (
                                                    <CardActions sx={{ justifyContent: 'center' }}>
                                                        <button onClick={() => handleMobilePhoneRemove(index, clientIndex)}
                                                            className="remove-btn"
                                                        >
                                                            Obriši
                                                        </button>
                                                    </CardActions>
                                                )}
                                            </div>
                                        ))}
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <button onClick={() => handleMobilePhoneAdd(clientIndex)}
                                                className="add-btn"
                                            >
                                                Dodaj
                                            </button>
                                        </CardActions>
                                    </Grid>}
                                    {client.clientType === '' ? null : <Grid paddingBottom={2}>
                                        {client.emails.map((singleEmail, index) => (
                                            <div key={index} className="services">
                                                <div className="first-division">
                                                    <Grid paddingBottom={2}
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label="E-mail"
                                                            type="search"
                                                            InputLabelProps={{
                                                                sx: {
                                                                    fontSize: "14px",
                                                                    fontWeight: 1000,
                                                                }
                                                            }}
                                                            name="email"
                                                            onChange={(e) => handleEmailChange(e, index, clientIndex)}
                                                            required
                                                            value={singleEmail.email}
                                                            inputProps={{
                                                                style: { fontSize: 15 }
                                                            }}
                                                        />
                                                    </Grid>
                                                </div>
                                                {client.emails.length !== 1 && (
                                                    <CardActions sx={{ justifyContent: 'center' }}>
                                                        <button onClick={() => handleEmailRemove(index, clientIndex)}
                                                            className="remove-btn"
                                                        >
                                                            Obriši
                                                        </button>
                                                    </CardActions>
                                                )}
                                            </div>
                                        ))}
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <button onClick={() => handleEmailAdd(clientIndex)}
                                                className="add-btn"
                                            >
                                                Dodaj
                                            </button>
                                        </CardActions>
                                    </Grid>}
                                    {client.clientType === '' ? null : <Grid paddingBottom={2}>
                                        <FormControl sx={{ width: "100%" }}>
                                            <InputLabel required sx={{ fontSize: "14px", fontWeight: 1000 }}
                                                id="demo-multiple-checkbox-label" > Način obračuna advokatske nagrade</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                multiple
                                                style={{ fontSize: 15 }}
                                                value={client.awardCalculationMethods}
                                                onChange={(e) => handleAwardCalculationMethodsChange(e, clientIndex)}
                                                input={<OutlinedInput id="select-multiple-chip" label="Tag" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip style={{ fontSize: 15 }} key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}

                                                MenuProps={MenuProps}
                                            >
                                                {awardCalculationMethods.map((option) => (
                                                    <MenuItem
                                                        key={option.value}
                                                        value={option.label}
                                                        style={getStyles(option.value, client.awardCalculationMethods, theme)}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>}

                                    {client.clientType === '' ? null : <Grid paddingBottom={2}>
                                        <TextField
                                            fullWidth
                                            label="Način obračuna advokatskih troškova"
                                            type="search"
                                            InputLabelProps={{
                                                sx: {
                                                    fontSize: "14px",
                                                    fontWeight: 1000,
                                                }
                                            }}
                                            name="costCalculationMethod"
                                            onChange={(e) => handleChange(e, clientIndex, clientIndex)}
                                            required
                                            select
                                            SelectProps={{ native: true }}
                                            value={client.costCalculationMethod}
                                            inputProps={{
                                                style: { fontSize: 15 }
                                            }}
                                        >
                                            {costCalculationMethods.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    }
                                </Grid>
                            ))}


                            <CardActions sx={{ justifyContent: 'center' }}>
                                <button className="formFieldButtonAddClient"
                                    onClick={() => handleAddClient()}
                                    type="submit">
                                    Dodaj klijenta
                                </button>
                            </CardActions>
                        </Grid>
                    </FormikStep>
                    <FormikStep
                        label="Postupak">
                        <Grid>
                            <Box paddingBottom={2}>
                                <TextField
                                    fullWidth
                                    type="search"
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    label="Broj predmeta"
                                    name="procedureNumber"
                                    onChange={(e) => handleProcedureChange(e)}
                                    required
                                    value={procedure.procedureNumber}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Box>
                            <Grid>
                                <FormLabel component="legend" style={{ fontSize: '2rem' }}>Suprotne strane</FormLabel>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="suprotneStrane"
                                            checked={procedure.suprotneStrane}
                                            onChange={handleProcedureCheckedChange}
                                        />
                                    }
                                    label={<span style={{ fontSize: '1.3rem' }}>{"Postoje suprotne strane u postupku?"}</span>}
                                />
                            {procedure.suprotneStrane === false ? null :
                                <Box paddingBottom={2}>
                                    <TextField
                                        fullWidth
                                        type="search"
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: "14px",
                                                fontWeight: 1000,
                                            }
                                        }}
                                        label="Ime i prezime/naziv suprotne strane"
                                        name="name"
                                        onChange={(e) => handleProcedureSuprotneStraneChange(e)}
                                        required
                                        value={procedure.suprotnaStrana.name}
                                        inputProps={{
                                            style: { fontSize: 15 }
                                        }}
                                    />
                                </Box>}
                            {procedure.suprotneStrane === false ? null :
                                <Box paddingBottom={2}>
                                    <TextField
                                        fullWidth
                                        type="search"
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: "14px",
                                                fontWeight: 1000,
                                            }
                                        }}
                                        label="Mesto prebivališta/sedišta suprotne strane"
                                        name="adrress"
                                        onChange={(e) => handleProcedureSuprotneStraneChange(e)}
                                        required
                                        value={procedure.suprotnaStrana.adrress}
                                        inputProps={{
                                            style: { fontSize: 15 }
                                        }}
                                    />
                                    </Box>}
                            </Grid>
                            <Box paddingBottom={2}>
                                <TextField fullWidth
                                    label="Vrsta postupka"
                                    type="search"
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="procedureType"
                                    onChange={(e) => handleProcedureChange(e)}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={procedure.procedureType}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                >
                                    {procedureTypes.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Box>
                            {procedure.procedureType === "" ? null : <Box paddingBottom={2}>
                                <TextField fullWidth
                                    label="Svojstvo klijenta u postupku"
                                    type="search"
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="svojstvoKlijenta"
                                    onChange={(e) => handleProcedureChange(e)}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={procedure.svojstvoKlijenta}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                >
                                    {procedure.procedureType === "0" ? krivicniTypes.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    )) :
                                        procedure.procedureType === "1" ? prekrsajniPrivredniTypes.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.procedureType === "2" ? parnicaTypes.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.procedureType === "3" ? izvrsenjeTypes.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) :
                                                    procedure.procedureType === "4" ? vanparnicaTypes.map((option) => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    )) :
                                                        procedure.procedureType === "5" ? rgzTypes.map((option) => (
                                                            <option
                                                                key={option.value}
                                                                value={option.value}
                                                            >
                                                                {option.label}
                                                            </option>
                                                        )) :
                                                            procedure.procedureType === "6" ? stecajLikvidacijaTypes.map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            )) :
                                                                procedure.procedureType === "7" ? updavniPostupakTypes.map((option) => (
                                                                    <option
                                                                        key={option.value}
                                                                        value={option.value}
                                                                    >
                                                                        {option.label}
                                                                    </option>
                                                                )) :
                                                                    procedure.procedureType === "8" ? updavniSporTypes.map((option) => (
                                                                        <option
                                                                            key={option.value}
                                                                            value={option.value}
                                                                        >
                                                                            {option.label}
                                                                        </option>
                                                                    )) :
                                                                        procedure.procedureType === "9" ? upisRegistarNijeRGZTypes.map((option) => (
                                                                            <option
                                                                                key={option.value}
                                                                                value={option.value}
                                                                            >
                                                                                {option.label}
                                                                            </option>
                                                                        )) :
                                                                            procedure.procedureType === "10" ? postupakPredPoslodavcemTypes.map((option) => (
                                                                                <option
                                                                                    key={option.value}
                                                                                    value={option.value}
                                                                                >
                                                                                    {option.label}
                                                                                </option>
                                                                            )) :
                                                                                procedure.procedureType === "11" ? ustavniTypes.map((option) => (
                                                                                    <option
                                                                                        key={option.value}
                                                                                        value={option.value}
                                                                                    >
                                                                                        {option.label}
                                                                                    </option>
                                                                                )) :
                                                                                    procedure.procedureType === "12" ? medjunarodniTypes.map((option) => (
                                                                                        <option
                                                                                            key={option.value}
                                                                                            value={option.value}
                                                                                        >
                                                                                            {option.label}
                                                                                        </option>
                                                                                    )) :
                                                                                        procedure.procedureType === "13" ? domacaArbitrazaTypes.map((option) => (
                                                                                            <option
                                                                                                key={option.value}
                                                                                                value={option.value}
                                                                                            >
                                                                                                {option.label}
                                                                                            </option>
                                                                                        )) :
                                                                                            procedure.procedureType === "14" ? ostaliPostupciTypes.map((option) => (
                                                                                                <option
                                                                                                    key={option.value}
                                                                                                    value={option.value}
                                                                                                >
                                                                                                    {option.label}
                                                                                                </option>
                                                                                            )) :
                                                                                                procedure.procedureType === "15" ? ostaleUslugeTypes.map((option) => (
                                                                                                    <option
                                                                                                        key={option.value}
                                                                                                        value={option.value}
                                                                                                    >
                                                                                                        {option.label}
                                                                                                    </option>
                                                                                                )) : null}
                                </TextField>
                            </Box>}
                            {procedure.procedureType === "" ? null : < Box paddingBottom={2}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    label={procedure.procedureType === "0" ? "Visina zaprećene kazne" : "Vrednost postupka"}
                                    name="kazna"
                                    onChange={(e) => handleProcedureChange(e)}
                                    required
                                    value={procedure.kazna}
                                    inputProps={{
                                        style: { fontSize: 15 },
                                        inputProps: { min: 0 }
                                    }}
                                />
                            </Box>}
                            {procedure.procedureType === "" ? null : < Box paddingBottom={2}>
                                <TextField
                                    fullWidth
                                    type="search"
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    label="Prvostepeni organ"
                                    name="prvostepeniOrgan"
                                    onChange={(e) => handleProcedureChange(e)}
                                    required
                                    value={procedure.prvostepeniOrgan}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Box>}
                            {procedure.procedureType === "" ? null : < Box paddingBottom={2}>
                                <TextField
                                    fullWidth
                                    type="search"
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    label="Drugostepeni organ"
                                    name="drugostepeniOrgan"
                                    onChange={(e) => handleProcedureChange(e)}
                                    required
                                    value={procedure.drugostepeniOrgan}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Box>}
                            {procedure.procedureType === "" ? null : <Box paddingBottom={2}>
                                <TextField fullWidth
                                    label="Izbor organa"
                                    type="search"
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="izborOrgana"
                                    onChange={(e) => handleProcedureChange(e)}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={procedure.izborOrgana}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                >
                                    {organi.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Box> }
                        </Grid>
                    </FormikStep>
                    <FormikStep label="Detalji postupka">
                        {procedure.procedureType !== "0" ? null : <Box paddingBottom={2}>
                            <TextField fullWidth
                                label="Izbor po težini dela"
                                type="search"
                                InputLabelProps={{
                                    sx: {
                                        fontSize: "14px",
                                        fontWeight: 1000,
                                    }
                                }}
                                name="krivicniTezinaDelaType"
                                onChange={(e) => handleProcedureChange(e)}
                                required
                                select
                                SelectProps={{ native: true }}
                                value={procedure.krivicniTezinaDela}
                                inputProps={{
                                    style: { fontSize: 15 }
                                }}
                            >
                                {krivicniTezinaDela.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </Box>}
                        {procedure.procedureType === "" ? null : <Box paddingBottom={2}>
                            <TextField fullWidth
                                label="Izbor po vrsti"
                                type="search"
                                InputLabelProps={{
                                    sx: {
                                        fontSize: "14px",
                                        fontWeight: 1000,
                                    }
                                }}
                                name="vrstaPostupkaType"
                                onChange={(e) => handleProcedureChange(e)}
                                required
                                select
                                SelectProps={{ native: true }}
                                value={procedure.vrstaPostupkaType}
                                inputProps={{
                                    style: { fontSize: 15 }
                                }}
                            >
                                {procedure.procedureType === "1" ? prekrsajniPrivredniPrestupiPoVrstiPostupka.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    )) :
                                        procedure.procedureType === "2" ? parnicniPoVrstiPostupka.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.procedureType === "3" ? izvrsniPostupakPoVrstiPostupka.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.procedureType === "4" ? vanparnicniPoVrstiPostupka.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) :
                                                    procedure.procedureType === "5" ? RGZPoVrstiPostupka.map((option) => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    )) :
                                                        procedure.procedureType === "6" ? stecajniLikvidacioniPoVrstiPostupka.map((option) => (
                                                            <option
                                                                key={option.value}
                                                                value={option.value}
                                                            >
                                                                {option.label}
                                                            </option>
                                                        )) :
                                                            procedure.procedureType === "7" ? upravniPoVrstiPostupka.map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            )) :
                                                                procedure.procedureType === "8" ? upravniSporPoVrstiPostupka.map((option) => (
                                                                    <option
                                                                        key={option.value}
                                                                        value={option.value}
                                                                    >
                                                                        {option.label}
                                                                    </option>
                                                                )) :
                                                                    procedure.procedureType === "9" ? upisuRegistarPoVrstiPostupka.map((option) => (
                                                                        <option
                                                                            key={option.value}
                                                                            value={option.value}
                                                                        >
                                                                            {option.label}
                                                                        </option>
                                                                    )) :
                                                                        procedure.procedureType === "10" ? postupakPredPoslodavcemPoVrstiPostupka.map((option) => (
                                                                            <option
                                                                                key={option.value}
                                                                                value={option.value}
                                                                            >
                                                                                {option.label}
                                                                            </option>
                                                                        )) :
                                                                            //procedure.procedureType === "11" ? ustavniPoVrstiPostupka.map((option) => (
                                                                            //    <option
                                                                            //        key={option.value}
                                                                            //        value={option.value}
                                                                            //    >
                                                                            //        {option.label}
                                                                            //    </option>
                                                                            //)) :
                                                                                procedure.procedureType === "12" ? medjunarodniSudPoVrstiPostupka.map((option) => (
                                                                                    <option
                                                                                        key={option.value}
                                                                                        value={option.value}
                                                                                    >
                                                                                        {option.label}
                                                                                    </option>
                                                                                )) :
                                                                                    procedure.procedureType === "13" ? domacaArbitrazaPoVrstiPostupka.map((option) => (
                                                                                        <option
                                                                                            key={option.value}
                                                                                            value={option.value}
                                                                                        >
                                                                                            {option.label}
                                                                                        </option>
                                                                                    )) :
                                                                                        procedure.procedureType === "14" ? ostaliPostupciPoVrstiPostupka.map((option) => (
                                                                                            <option
                                                                                                key={option.value}
                                                                                                value={option.value}
                                                                                            >
                                                                                                {option.label}
                                                                                            </option>
                                                                                        )) :
                                                                                            procedure.procedureType === "15" ? sastavljanjeIspravaPoVrstiPostupka.map((option) => (
                                                                                                <option
                                                                                                    key={option.value}
                                                                                                    value={option.value}
                                                                                                >
                                                                                                    {option.label}
                                                                                                </option>
                                                                                            )) : null}
                            </TextField>
                        </Box>}
                        {procedure.procedureType === "" ? null : <Box paddingBottom={2}>
                            <TextField fullWidth
                                label="Izbor opštih radnji"
                                type="search"
                                InputLabelProps={{
                                    sx: {
                                        fontSize: "14px",
                                        fontWeight: 1000,
                                    }
                                }}
                                name="opsteRadnjeType"
                                onChange={(e) => handleProcedureChange(e)}
                                required
                                select
                                SelectProps={{ native: true }}
                                value={procedure.opsteRadnjeType}
                                inputProps={{
                                    style: { fontSize: 15 }
                                }}
                            >
                                {procedure.procedureType === "0" ? krivicniOpsteRadnje.map((option) => (
                                    <option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                )) :
                                    procedure.procedureType === "1" ? prekrsajniPrivredniPrestupiOpsteRadnje.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    )) :
                                        procedure.procedureType === "2" ? parnicniOpsteRadnje.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.procedureType === "3" ? izvrsniPostupakOpsteRadnje.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.procedureType === "4" ? vanparnicniOpsteRadnje.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) :
                                                    procedure.procedureType === "5" ? RGZOpsteRadnje.map((option) => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    )) :
                                                        procedure.procedureType === "6" ? stecajniLikvidacioniOpsteRadnje.map((option) => (
                                                            <option
                                                                key={option.value}
                                                                value={option.value}
                                                            >
                                                                {option.label}
                                                            </option>
                                                        )) :
                                                            procedure.procedureType === "7" ? upravniOpsteRadnje.map((option) => (
                                                                <option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </option>
                                                            )) :
                                                                procedure.procedureType === "8" ? upravniSporOpsteRadnje.map((option) => (
                                                                    <option
                                                                        key={option.value}
                                                                        value={option.value}
                                                                    >
                                                                        {option.label}
                                                                    </option>
                                                                )) :
                                                                    procedure.procedureType === "9" ? upisuRegistarOpsteRadnje.map((option) => (
                                                                        <option
                                                                            key={option.value}
                                                                            value={option.value}
                                                                        >
                                                                            {option.label}
                                                                        </option>
                                                                    )) :
                                                                        procedure.procedureType === "10" ? postupakPredPoslodavcemOpsteRadnje.map((option) => (
                                                                            <option
                                                                                key={option.value}
                                                                                value={option.value}
                                                                            >
                                                                                {option.label}
                                                                            </option>
                                                                        )) :
                                                                            procedure.procedureType === "11" ? ustavniOpsteRadnje.map((option) => (
                                                                                <option
                                                                                    key={option.value}
                                                                                    value={option.value}
                                                                                >
                                                                                    {option.label}
                                                                                </option>
                                                                            )) :
                                                                                procedure.procedureType === "12" ? medjunarodniSudOpsteRadnje.map((option) => (
                                                                                    <option
                                                                                        key={option.value}
                                                                                        value={option.value}
                                                                                    >
                                                                                        {option.label}
                                                                                    </option>
                                                                                )) :
                                                                                    procedure.procedureType === "13" ? domacaArbitrazaOpsteRadnje.map((option) => (
                                                                                        <option
                                                                                            key={option.value}
                                                                                            value={option.value}
                                                                                        >
                                                                                            {option.label}
                                                                                        </option>
                                                                                    )) :
                                                                                        procedure.procedureType === "14" ? ostaliPostupciOpsteRadnje.map((option) => (
                                                                                            <option
                                                                                                key={option.value}
                                                                                                value={option.value}
                                                                                            >
                                                                                                {option.label}
                                                                                            </option>
                                                                                        )) : null}
                            </TextField>
                        </Box>}
                        {procedure.procedureType === "" ? null : <Box paddingBottom={2}>
                            <TextField fullWidth
                                label="Izbor posebnih radnji"
                                type="search"
                                InputLabelProps={{
                                    sx: {
                                        fontSize: "14px",
                                        fontWeight: 1000,
                                    }
                                }}
                                name="posebneRadnjeType"
                                onChange={(e) => handleProcedureChange(e)}
                                required
                                select
                                SelectProps={{ native: true }}
                                value={procedure.posebneRadnjeType}
                                inputProps={{
                                    style: { fontSize: 15 }
                                }}
                            >
                                {procedure.procedureType === "0" ?
                                    procedure.opsteRadnjeType === "0" ? krivicniIzborPosebnihRadnji0.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    )) :
                                        procedure.opsteRadnjeType === "1" ? krivicniIzborPosebnihRadnji1.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "2" ? krivicniIzborPosebnihRadnji2.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "3" ? krivicniIzborPosebnihRadnji3.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) :
                                                    procedure.opsteRadnjeType === "4" ? krivicniIzborPosebnihRadnji4.map((option) => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    )) :
                                                        procedure.opsteRadnjeType === "5" || procedure.opsteRadnjeType === "6" ? krivicniIzborPosebnihRadnji5i6.map((option) => (
                                                            <option
                                                                key={option.value}
                                                                value={option.value}
                                                            >
                                                                {option.label}
                                                            </option>
                                                        )) : null
                                    : procedure.procedureType === "1" ?
                                        procedure.opsteRadnjeType === "0" ? prekrsajniPrivredniPrestupiIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? prekrsajniPrivredniPrestupiIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? prekrsajniPrivredniPrestupiIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "2" ?
                                        procedure.opsteRadnjeType === "0" ? parnicniIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? parnicniIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? parnicniIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "3" ?
                                        procedure.opsteRadnjeType === "0" ? izvrsniPostupakIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? izvrsniPostupakIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? izvrsniPostupakIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "4" ?
                                        procedure.opsteRadnjeType === "0" ? vanparnicniIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? vanparnicniIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? vanparnicniIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "5" ?
                                        procedure.opsteRadnjeType === "0" ? RGZIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? RGZIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? RGZIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "6" ?
                                        procedure.opsteRadnjeType === "0" ? stecajniLikvidacioniIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? stecajniLikvidacioniIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? stecajniLikvidacioniIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "7" ?
                                        procedure.opsteRadnjeType === "0" ? upravniIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? upravniIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? upravniIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "8" ?
                                        procedure.opsteRadnjeType === "0" ? upravniSporIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? upravniSporIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? upravniSporIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "9" ?
                                        procedure.opsteRadnjeType === "0" ? upisuRegistarIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? upisuRegistarIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? upisuRegistarIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "10" ?
                                        procedure.opsteRadnjeType === "0" ? postupakPredPoslodavcemIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? postupakPredPoslodavcemIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? postupakPredPoslodavcemIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "11" ?
                                        procedure.opsteRadnjeType === "0" ? ustavniIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? ustavniIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) : null
                                    : procedure.procedureType === "12" ?
                                        procedure.opsteRadnjeType === "0" ? medjunarodniSudIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? medjunarodniSudIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? medjunarodniSudIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "13" ?
                                        procedure.opsteRadnjeType === "0" ? domacaArbitrazaIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? domacaArbitrazaIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) : null
                                    : procedure.procedureType === "14" ?
                                        procedure.opsteRadnjeType === "0" ? ostaliPostupciIzborPosebnihRadnji0.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )) :
                                            procedure.opsteRadnjeType === "1" ? ostaliPostupciIzborPosebnihRadnji1.map((option) => (
                                                <option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            )) :
                                                procedure.opsteRadnjeType === "2" ? ostaliPostupciIzborPosebnihRadnji2.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                )) : null
                                    : procedure.procedureType === "15" ?
                                        sastavljanjeIspravaIzborPosebnihRadnji.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))
                                    : procedure.procedureType === "16" ?
                                        sastavljanjeOpstihPojedinacnihAkataIzborPosebnihRadnji.map((option) => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        ))
                                    : null}
                            </TextField>
                        </Box>}
                    </FormikStep>
                    <FormikStep label="Pregled">
                        <Box paddingBottom={2}>
                            <Field
                                fullWidth
                                name="description"
                                component={TextField}
                                label="Description"
                            />
                        </Box>
                    </FormikStep>
                </FormikStepper>
            </CardContent>
        </Card>
    )
}

export function FormikStep({ children }) {
    return <>{children}</>
}

export function FormikStepper({ children, ...props }) {
    const childrenArray = React.Children.toArray(children)
    const [step, setStep] = useState(0)
    const currentChild = childrenArray[step]
    const [completed, setCompleted] = useState(false)

    function isLastStep() {
        return step === childrenArray.length - 1
    }

    return (
        <Formik
            {...props}
            validationSchema={currentChild.props.validationSchema}
            onSubmit={async (values, helpers) => {
                if (isLastStep()) {
                    await props.onSubmit(values, helpers)
                    setCompleted(true)
                } else {
                    setStep(s => s + 1)

                    // the next line was not covered in the youtube video
                    //
                    // If you have multiple fields on the same step
                    // we will see they show the validation error all at the same time after the first step!
                    //
                    // If you want to keep that behaviour, then, comment the next line :)
                    // If you want the second/third/fourth/etc steps with the same behaviour
                    //    as the first step regarding validation errors, then the next line is for you! =)
                    //
                    // In the example of the video, it doesn't make any difference, because we only
                    //    have one field with validation in the second step :)
                    helpers.setTouched({})
                }
            }}
        >
            {({ isSubmitting }) => (
                <Form autoComplete="off">
                    <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
                        {childrenArray.map((child, index) => (
                            <Step
                                key={child.props.label}
                                completed={step > index || completed}
                                sx={{ height: 100 }}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{child.props.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {currentChild}

                    <Grid container spacing={2} className="container">
                        {step > 0 ? (
                            <Grid>
                                <button className="formFieldButtonBack"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    onClick={() => setStep(s => s - 1)}>
                                    Back
                                </button>
                            </Grid>
                        ) : null}
                        <Grid>
                            <button className="formFieldButtonNext"
                                disabled={isSubmitting}
                                variant="contained"
                                type="submit">
                                {isSubmitting ? "Submitting" : isLastStep() ? "Submit" : "Dalje"}
                            </button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}