import React, { Component } from 'react';
import Procedures from "../views/advokat/Procedures/Procedures";
import Account from "../views/advokat/Account/Account";
import Settings from "../views/advokat/Settings/Settings";
import NewProcedure from "../views/advokat/NewProcedure/NewProcedure";

const advokatRoutes = [
    {
        path: "/",
        element: < Procedures />
    },
    {
        path: "/account",
        element: <Account />
    },
    {
        path: "/settings",
        element: <Settings />
    },
    {
        path: "/new-procedure",
        element: <NewProcedure />
    },
];
export default advokatRoutes;
