import './Settings.css'


const Settings = () => {
    return (
        <div className="prob2" >

        </div>
    )
}

export default Settings