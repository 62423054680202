import { AccountProfile } from './account-profile';
import AccountProfileDetails from './account-profile-details';

const Account = () => {
    return (
        <div className="myMain" >
            <AccountProfile className="av" />
            <br/>
            <AccountProfileDetails />
        </div>
    )
}


export default Account;