import "./Procedures.css"
import Datatable from "../../../components/advokat/Content/Datatable/Datatable"

const Procedures = () => {
    return (
        <div className="main-content-holder">
            <div className="content-grid-one">
                <Datatable />
            </div>
        </div>
  )
}

export default Procedures