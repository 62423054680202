import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './SmsCode.css'

const SmsCode = () => {
    const [smsCode, setSmsCode] = useState('')
    const navigate = useNavigate()

    const handleSmsCodeInput = (e) => setSmsCode(e.target.value)

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("SMS CODE:");
        console.log(smsCode);

        navigate('/advokat');
    }

    return (
        <div className="App1">
            <div className="appAside" />
            <div className="appForm">


                <div className="formTitle">
                    <p className="formTitleLink"> Unesite sigrnosni kod </p>
                </div>
                <p className="formText"> Unesite sigrnosni kod koji Vam je stigao kao SMS poruka na broj +381 64 ** ** 296: </p>

                <div className="formCenter">
                    <form className="formFields" onSubmit={handleSubmit}>
                        <div className="formField">
                            <label className="formFieldLabel" htmlFor="securityCode">
                                Sigurnosni kod
                            </label>
                            <input
                                type="smsCode"
                                id="smsCode"
                                className="formFieldInput"
                                placeholder="Unesite sigurnosni kod"
                                name="securityCode"
                                value={smsCode}
                                onChange={handleSmsCodeInput}
                            />
                        </div>
                        <div className="formField">
                            <button className="formFieldButton">Prijavi se</button>{" "}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SmsCode;
