import { useEffect, useState } from 'react';
import { personsImgs } from '../../../utils/images';
import { navigationLinksAdvokat } from '../../../data/data';
import "./SidebarAdvokat.css";
import { useContext } from 'react';
import { SidebarContext } from '../../../components/advokat/Content/Sidebar/Sidebar.jsx';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    const location = useLocation();
    const { pathname } = location;
    const [activeLinkIdx, setActiveLinkIdx] = useState(pathname === "/advokat/account" ? 2 : pathname === "/advokat/settings" ? 3 : 1);
    const [sidebarClass, setSidebarClass] = useState("");
    const { isSidebarOpen } = useContext(SidebarContext);

    const onClickItemInSidebar = (id) => {
        if (activeLinkIdx !== id) {
            setActiveLinkIdx(id);
        }
    };

    useEffect(() => {
    if(isSidebarOpen){
        setSidebarClass('sidebar-change');
    } else {
        setSidebarClass('');
    }
    }, [isSidebarOpen]);

  return (
    <div className={ `sidebar ${sidebarClass}` }>
      <div className="user-info">
          <div className="info-img img-fit-cover">
              <img src={ personsImgs.person_two } alt="profile image" />
          </div>
          <span className="info-name">alice-doe</span>
      </div>

      <nav className="navigation">
          <ul className="nav-list">
            {
                navigationLinksAdvokat.map((navigationLink) => (
                    <li className="nav-item" key={navigationLink.id} onClick={() => onClickItemInSidebar(navigationLink.id)}>
                        <Link to={navigationLink.path} className={ `nav-link ${navigationLink.id === activeLinkIdx ? 'active' : null}` }>
                          <img src={ navigationLink.image } className="nav-link-icon" alt = { navigationLink.title } />
                          <span className="nav-link-text">{ navigationLink.title }</span>
                        </Link >
                    </li>
                ))
            }
          </ul>
      </nav>
    </div>
  )
}

export default Sidebar
