import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import './TermsOfUse.css'

const TermsOfUse = () => {

    const navigate = useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault();

        navigate('/login');
    }

    return (
        <div className="App1">
            <div className="appAside" />
            <div className="appForm">


                <div className="formTitle">
                    <p className="formTitleLink"> Uslovi korišćenja </p>

                </div>
                <div className="formCenter">
                    <div className="formField">
                        <p>
                            Ovi Uslovi korišćenja („Uslovi korišćenja“)
                            primenjuju se na usluge eDvokat doo Beograd
                            („eDvokat“) kojima se pristupa preko online
                            Platforme („Platforma“). Uslovi korišćenja se
                            primenjuju i na Usluge kojima se pristupa preko
                            bilo koje druge aplikacije ili koje se dobijaju u
                            neposrednoj komunikaciji. Vaše korišćenje
                            Usluga je regulisano ovim Uslovima korišćenja.
                            eDvokat može dopuniti i izmeniti Uslove
                            korišćenja u bilo koje vreme bez obaveze da
                            Vas posebno obavesti. Vaša je obaveza da
                            redovno proveravate aktuelnu verziju Uslova
                            korišćenja.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            PRUŽANJE USLUGE
                        </label>
                        <p>
                            Usluge se mogu koristiti samo u zakonite svrhe,
                            u granicama prinudnih propisa, javnog poretka i
                            dobrih običaja. Saglasni ste da ćete poštovati
                            sve važeće zakone, pravila i propise u vezi sa
                            Vašim korišćenjem Usluge. Odnos između nas i
                            Vas poseduje pravnu, etičku i profesionalnu
                            dimenziju i Vaša je dužnost da postupate u
                            okviru granica ponašanja koje je odredio naš
                            zaposleni, ili konsultant koji radi po našem
                            nalogu, a sa kojim sarađujete. Svako Vaše
                            ponašanje koje po našoj proceni krši ove
                            Uslove korišćenja na bilo koji način, može
                            dovesti do prekida ili ukidanja Usluga, ili
                            uklanjanja Vašeg naloga, sa ili bez prethodnog
                            obaveštenja.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            PODOBNOST
                        </label>
                        <p>
                            Morate imati najmanje 18 (osamnaest) godina.
                            Nemate pravo na korišćenje Usluga ako Vam je
                            prethodno bilo koja Usluga eDvokata prekinuta
                            ili ukinuta iz razloga nepoštovanja Uslova
                            korišćenja.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            NALOG
                        </label>
                        <p>
                            Prihvatate da pružite tačne podatke prilikom i
                            nakon kreiranja naloga, da održavate
                            bezbednost Vašeg naloga na način što nećete
                            otkriti Vašu lozinku, niti omogućiti korišćenje
                            naloga trećem licu, da ćete bez odlaganja
                            obavestiti eDvokat ako posumnjate da je
                            bezbednost naloga ugrožena, kao i da ćete
                            preuzeti odgovornost za sve aktivnosti izvršene
                            preko Vašeg naloga, uključujući i rizik od
                            neovlašćenog pristupa.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            ZABRANJEN SADRŽAJ
                        </label>
                        <p>
                            Ne smete da otpremate, koristite, sačinjavate,
                            prenosite, čuvate, prikazujete, distribuirate,
                            delite ili na drugi način stavljate na raspolaganje
                            bilo kakav sadržaj, opis ili podatak u vezi sa
                            korišćenjem Usluga:
                        </p>
                        <lu>
                            <li>
                                koji je zabranjen od strane privrednog
                                subjekta koji je vlasnik tog sadržaja,
                                opisa ili podatka;
                            </li>
                            <li>
                                koji je nezakonit ili kojim se vrši ili
                                omogućava izvršenje bilo kog krivičnog
                                dela, privrednog prestupa ili prekršaja,
                                ili koji može dovesti do građansko-
                                pravne odgovornosti;
                            </li>
                            <li>
                                koji može dovesti do rizika od Vaše
                                povrede, povrede drugog lica, povrede
                                životinje, ili povrede prava svojine;
                            </li>
                            <li>
                                koji je opscen, pornografski, nepristojan
                                i seksualno eksplicitan;
                            </li>
                            <li>
                                koji prikazuje eksplicitno nasilje, ili
                                upućuje bilo kakve pretnje na izvršenje
                                nasilja;
                            </li>
                            <li>
                                koji je uvredljiv, uznemiravajući, mučan
                                ili na drugi način neprikladan za druge;
                            </li>
                            <li>
                                koji je rasistički i ugrožava prava
                                etničkih zajednica;
                            </li>
                            <li>
                                kojim nemate pravo da raspolažete;
                            </li>
                            <li>
                                koji znate da je neistinit ili
                            </li>
                            <li>
                                kojim ugrožavate intelektualna prava i
                                vlasništvo drugih.
                            </li>
                        </lu>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            OGRANIČENJA KORIŠĆENJA USLUGE
                        </label>
                        <p>
                            Vi ste isključivo odgovorni za svoje ponašanje u
                            vezi sa Uslugama. Dužni ste da ne vršite
                            sledeće:
                        </p>
                        <lu>
                            <li>
                                iznajmljivanje, pozajmljivanje, prodaja i
                                davanje u podzakup Usluga;
                            </li>
                            <li>
                                korišćenje Usluga radi kreiranja
                                sopstvenih derivativnih Usluga;
                            </li>
                            <li>
                                distribuiranje Usluga ili činjenje Usluga
                                dostupnim preko mreže, kako bi mogle
                                biti korišćene preko više različitih
                                uređaja u isto vreme;
                            </li>
                            <li>
                                menjanje, otkrivanje ili preoblikovanje
                                bilo koje Usluge, u celosti, ili delimično;
                            </li>
                            <li>
                                uklanjanje, menjanje ili prikrivanje
                                obaveštenja ili oznaka o vlasničkim
                                pravima na Uslugama i u vezi sa
                                Uslugama;
                            </li>
                            <li>
                                korišćenje Usluga na način koji bi
                                mogao negativno da utiče na druge
                                korisnike Usluga, ili koji bi mogao da
                                utiče na funkcionalnost Usluge;
                            </li>
                            <li>
                                pokušavanje dobijanja neovlašćenog
                                pristupa Uslugama ili sa njima
                                povezanim sistemima;
                            </li>
                            <li>
                                lažno predstavljanje ili korišćenje
                                Usluga lažno predstavljajući svoju
                                povezanost sa drugima;
                            </li>
                            <li>
                                sačinjavanje konkurentskog proizvoda
                                ili usluge, ili kopiranje bilo koje
                                funkcionalnosti Usluge ili
                            </li>
                            <li>
                                otkrijete trećoj strani bilo koju analizu u
                                vezi sa Uslugom.
                            </li>
                        </lu>
                        <p>
                            Ako prekršite bilo koju obavezu navedenu u
                            prethodnom stavu, Vaše pravo na korišćenje
                            Usluga će odmah prestati, po automatizmu.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            POVRATNE INFORMACIJE
                        </label>
                        <p>
                            eDvokat može periodično zahtevati od Vas
                            povratne informacije u vezi sa iskustvima u
                            korišćenju Usluga. Saglasni ste da se odričete
                            bilo kakvih prava na datim povratnim
                            informacijama.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            NE VRŠENJE JAVNIH USLUGA
                        </label>
                        <p>
                            Ništa što Vam se daje kroz Uslugu ne
                            predstavlja javnu uslugu, koju isključivo daju
                            nadležni državni organi. eDvokat nije i ne može
                            biti odgovoran za nemogućnost izvršenja
                            Usluge, usled negativnog odgovora nadležnog
                            državnog organa na podneti zahtev, usled
                            neostvarivanja uslova za odobrenje zahteva iz
                            razloga za koji nisu odgovorni eDvokat i
                            angažovani konsultanti i treća lica koja vrše
                            Usluge po našem nalogu (nedostavljanje
                            odgovarajuće dokumentacije od strane
                            Korisnika, dostavljanje netačnih ili nepotpunih
                            podataka od strane Korisnika, prećutkivanje
                            bitnih okolnosti od značaja za uspešno izvršenje
                            usluge itd.).
                        </p>
                    </div>
                </div>
                <div className="buttonOnCenter">
                    <form className="formFields" onSubmit={handleSubmit}>
                        <button className="formFieldButton">Prihvatam</button>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default TermsOfUse;