import "./Account.css"
import { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    Unstable_Grid2 as Grid
} from '@mui/material';

const states = [
    {
        value: '0',
        label: 'Paušalac'
    },
    {
        value: '1',
        label: 'PDV obveznik'
    }
];

function AccountProfileDetails() {
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        name: '',
        street: '',
        ptt: '',
        city: '',
        brAdvokatskeLegitimacije: '',
        pib: '',
        state: '0',
        banks: [{ bank: "" }],
        phones: [{ phone: "" }],
        mobilePhones: [{ mobilePhone: "" }],
        emails: [{ email: "" }],
    });

    const handleEmailChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...values.emails];
        list[index][name] = value;
        console.log("aaa")
        console.log(index)
        console.log(value)
        setValues((prevState) => ({
            ...prevState,
            ["emails"]: list
        }));
    };
    const handlePhoneChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...values.phones];
        list[index][name] = value;
        setValues((prevState) => ({
            ...prevState,
            ["phones"]: list
        }));
    };
    const handleMobilePhoneChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...values.mobilePhones];
        list[index][name] = value;
        setValues((prevState) => ({
            ...prevState,
            ["mobilePhones"]: list
        }));
    };
    const handleBankChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...values.banks];
        list[index][name] = value;
        setValues((prevState) => ({
            ...prevState,
            ["banks"]: list
        }));
    };

    const handleChange = (event) => {
            console.log(values)
            setValues((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        }

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
        },
        []
    );

    const handleMobilePhoneRemove = (index) => {
        const list = [...values.mobilePhones];
        list.splice(index, 1);
        setValues((prevState) => ({
            ...prevState,
            ["mobilePhones"]: list
        }));
    };

    const handleMobilePhoneAdd = () => {
        const list = [...values.mobilePhones, { mobilePhone: "" }];
        setValues((prevState) => ({
            ...prevState,
            ["mobilePhones"]: list
        }));
    };

    const handlePhoneRemove = (index) => {
        const list = [...values.phones];
        list.splice(index, 1);
        setValues((prevState) => ({
            ...prevState,
            ["phones"]: list
        }));
    };

    const handlePhoneAdd = () => {
        const list = [...values.phones, { phone: "" }];
        setValues((prevState) => ({
            ...prevState,
            ["phones"]: list
        }));
    };

    const handleEmailRemove = (index) => {
        const list = [...values.emails];
        list.splice(index, 1);
        setValues((prevState) => ({
            ...prevState,
            ["emails"]: list
        }));
    };

    const handleEmailAdd = () => {
        const list = [...values.emails, { email: "" }];
        setValues((prevState) => ({
            ...prevState,
            ["emails"]: list
        }));
    };

    const handleBankRemove = (index) => {
        const list = [...values.banks];
        list.splice(index, 1);
        setValues((prevState) => ({
            ...prevState,
            ["banks"]: list
        }));
    };

    const handleBankAdd = () => {
        const list = [...values.banks, { bank: "" }];
        setValues((prevState) => ({
            ...prevState,
            ["banks"]: list
        }));
    };

    return (
        <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
        >
            <Card>
                <CardHeader
                    titleTypographyProps={{ variant: 'h2' }}
                    subheaderTypographyProps={{ variant: 'h4' } }
                    subheader="Sve informacije o Vašem nalogu se mogu urediti."
                    title="Vaš nalog"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    label="Ime"
                                    name="firstName"
                                    onChange={handleChange}
                                    required
                                    value={values.firstName}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Prezime"
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="lastName"
                                    onChange={handleChange}
                                    required
                                    value={values.lastName}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Naziv advokatske kancelarije"
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="name"
                                    onChange={handleChange}
                                    required
                                    value={values.name}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Ulica i broj"
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="street"
                                    onChange={handleChange}
                                    required
                                    value={values.street}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Poštanski broj"
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="ptt"
                                    onChange={handleChange}
                                    required
                                    value={values.ptt}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="mesto"
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="city"
                                    onChange={handleChange}
                                    required
                                    value={values.city}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Broj advokatske legitimacije"
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="brAdvokatskeLegitimacije"
                                    onChange={handleChange}
                                    required
                                    value={values.brAdvokatskeLegitimacije}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="PIB"
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="pib"
                                    onChange={handleChange}
                                    required
                                    value={values.pib}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <TextField
                                    fullWidth
                                    label="Poreski status"
                                    type="search" 
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: "14px",
                                            fontWeight: 1000,
                                        }
                                    }}
                                    name="state"
                                    onChange={handleChange}
                                    required
                                    select
                                    SelectProps={{ native: true }}
                                    value={values.state}
                                    inputProps={{
                                        style: { fontSize: 15 }
                                    }}
                                >
                                    {states.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>                            
                        </Grid>
                        <br/>
                        <Grid>
                            {values.banks.map((singleBank, index) => (
                                <div key={index} className="services">
                                    <div className="first-division">
                                        <Grid 
                                            xs={12}
                                            md={6}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Račun i naziv banke"
                                                type="search" 
                                                InputLabelProps={{
                                                    sx: {
                                                        fontSize: "14px",
                                                        fontWeight: 1000,
                                                    }
                                                }}
                                                name="bank"
                                                onChange={(e) => handleBankChange(e, index)}
                                                required
                                                value={singleBank.bank}
                                                inputProps={{
                                                    style: { fontSize: 15 }
                                                }}
                                            />
                                        </Grid>
                                    </div>
                                    {values.banks.length !== 1 && (
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <button onClick={() => handleBankRemove(index)}
                                                    className="remove-btn"
                                            >
                                                Obriši
                                            </button>
                                        </CardActions>
                                    )}
                                </div>
                            ))}
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <button onClick={handleBankAdd}
                                    className="add-btn"
                                >
                                    Dodaj
                                </button>
                            </CardActions>
                        </Grid>
                        <br/>
                        <Grid>
                            {values.phones.map((singlePhone, index) => (
                                <div key={index} className="services">
                                    <div className="first-division">
                                        <Grid
                                            xs={12}
                                            md={6}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Kontakt telefon u kancelariji"
                                                type="search" 
                                                InputLabelProps={{
                                                    sx: {
                                                        fontSize: "14px",
                                                        fontWeight: 1000,
                                                    }
                                                }}
                                                name="phone"
                                                onChange={(e) => handlePhoneChange(e, index)}
                                                required
                                                value={singlePhone.phone}
                                                inputProps={{
                                                    style: { fontSize: 15 }
                                                }}
                                            />
                                        </Grid>
                                    </div>
                                    {values.phones.length !== 1 && (
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <button onClick={() => handlePhoneRemove(index)}
                                                    className="remove-btn"
                                            >
                                                Obriši
                                            </button>
                                        </CardActions>
                                    )}
                                </div>
                            ))}
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <button onClick={handlePhoneAdd}
                                    className="add-btn"
                                >
                                    Dodaj
                                </button>
                            </CardActions>
                        </Grid>
                        <br/>
                        <Grid>
                            {values.mobilePhones.map((singleMobilePhone, index) => (
                                <div key={index} className="services">
                                    <div className="first-division">
                                        <Grid
                                            xs={12}
                                            md={6}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Broj mobilnog telefon"
                                                type="search" 
                                                InputLabelProps={{
                                                    sx: {
                                                        fontSize: "14px",
                                                        fontWeight: 1000,
                                                    }
                                                }}
                                                name="mobilePhone"
                                                onChange={(e) => handleMobilePhoneChange(e, index)}
                                                required
                                                value={singleMobilePhone.mobilePhone}
                                                inputProps={{
                                                    style: { fontSize: 15 }
                                                }}
                                            />
                                        </Grid>
                                    </div>
                                    {values.mobilePhones.length !== 1 && (
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                        <button onClick={() => handleMobilePhoneRemove(index)}
                                                className="remove-btn"
                                        >
                                            Obriši
                                        </button>

                                        </CardActions>
                                    )}
                                </div>
                            ))}
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <button onClick={handleMobilePhoneAdd}
                                    className="add-btn"
                                >
                                    Dodaj
                                </button>
                            </CardActions>
                        </Grid>
                        <br/>
                        <Grid>
                            {values.emails.map((singleEmail, index) => (
                                <div key={index} className="services">
                                    <div className="first-division">
                                        <Grid 
                                            xs={12}
                                            md={6}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Email adresa ${index}"
                                                type="search" 
                                                InputLabelProps={{
                                                    sx: {
                                                        fontSize: "14px",
                                                        fontWeight: 1000,
                                                    }
                                                }}
                                                name="email"
                                                onChange={(e) => handleEmailChange(e, index)}
                                                required
                                                value={singleEmail.email}
                                                inputProps={{
                                                    style: { fontSize: 15 }
                                                }}
                                            />
                                        </Grid>
                                    </div>
                                    {values.emails.length !== 1 && (
                                        <CardActions sx={{ justifyContent: 'center' }}>
                                            <button onClick={() => handleEmailRemove(index)}
                                                className="remove-btn"
                                            >
                                                Obriši
                                            </button>
                                        </CardActions>
                                    )}
                                </div>
                            ))}
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <button onClick={handleEmailAdd}
                                    className="add-btn"
                                >
                                    Dodaj
                                </button>
                            </CardActions>
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
                <br />
                <br />
                <CardActions sx={{ justifyContent: 'center' }}>
                    <button className="buttonSave">
                        Sačuvaj informacije
                    </button>
                </CardActions>
            </Card>
        </form>
    );
};

export default AccountProfileDetails;