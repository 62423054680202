import React from 'react'
//import { useSelector, useDispatch } from 'react-redux'
import '../../app.css';
import Header from '../../components/home/header/Header';
import "../../assets/js/script";
import Services from '../../components/home/services/Services';
import About from '../../components/home/about/About';
import Qualities from '../../components/home/qualities/Qualities';
import Features from '../../components/home/features/Features';
import Portfolio from '../../components/home/portfolio/Portfolio';
import Testimonials from '../../components/home/testimonials/Testimonials';
import Contact from '../../components/home/contact/Contact';
import Footer from '../../components/home/footer/Footer';

function Home() {
    return (
        <div className="MainPage">
            <Header />
            <Services />
            <About />
            <Qualities />
            <Features />
            <Portfolio />
            <Testimonials />
            <Contact />
            <Footer />
        </div>
    );
}

export default Home;