export const advokatColumns = [
    {
        field: "id",
        headerName: "ID",
        width: 70
    },
    {
        field: "client",
        headerName: "Klijent",
        width: 230,
    },
    {
        field: "pib",
        headerName: "JMBG/PIB",
        width: 150,
    },
    {
        field: "slucaj",
        headerName: "Slučaj",
        width: 150,
    },
    {
        field: "email",
        headerName: "Email",
        width: 200,
    },

    {
        field: "date",
        headerName: "Datum",
        width: 150,
    },
    {
        field: "status",
        headerName: "Status",
        width: 100,
        renderCell: (params) => {
            return (
                <div className={`cellWithStatus ${params.row.status}`}>
                    {params.row.status}
                </div>
            );
        },
    },
];

//temporary data
export const advokatRows = [
    {
        id: 1,
        client: "Peta Petrovic",
        pib: "1234567891234",
        slucaj: "AP/01-2023",
        email: "emain1@gmail.com",
        date: "05.10.2023",
        status: "finished",
    },
    {
        id: 2,
        client: "Neko Nekovic",
        pib: "1234567895555",
        slucaj: "AP/18-2023",
        email: "emain2@gmail.com",
        date: "06.10.2023",
        status: "canceled",
    },
    {
        id: 3,
        client: "Zika Zikic",
        pib: "5555567891234",
        slucaj: "AP/20-2023",
        email: "emain3@gmail.com",
        date: "05.10.2023",
        status: "pending",
    },
    {
        id: 4,
        client: "NEKA FIRMA DOO",
        pib: "123456789",
        slucaj: "AP/04-2023",
        email: "emain4@gmail.com",
        date: "01.10.2023",
        status: "finished",
    },
    {
        id: 5,
        client: "Ime Imenkovic",
        pib: "1234565591234",
        email: "emain5@gmail.com",
        date: "15.10.2023",
        status: "pending",
    },
    {
        id: 6,
        client: "Ime Imenkovic",
        pib: "1234565591234",
        slucaj: "AP/12-2023",
        email: "emain5@gmail.com",
        date: "05.10.2023",
        status: "finished",
    },
    {
        id: 7,
        client: "Peta Petrovic",
        pib: "1234567891234",
        slucaj: "AP/78-2023",
        email: "emain1@gmail.com",
        date: "01.11.2023",
        status: "pending",
    },
    {
        id: 8,
        client: "Zivko Zivkovic",
        pib: "9876543211236",
        slucaj: "AP/2023",
        email: "emain8@gmail.com",
        date: "29.10.2023",
        status: "canceled",
    },
    {
        id: 9,
        client: "BLA BLA",
        slucaj: "AP/90-2023",
        pib: "6549871236547",
        email: "emain9@gmail.com",
        date: "01.09.2023",
        status: "finished",
    },
    {
        id: 10,
        client: "Cica Cicic",
        pib: "3698521478052",
        slucaj: "AP/50-2023",
        email: "emain10@gmail.com",
        date: "15.11.2023",
        status: "pending",
    },
    {
        id: 11,
        client: "Peta Petrovic",
        pib: "0147852369654",
        slucaj: "AP/25-2023",
        email: "emain11@gmail.com",
        date: "16.11.2023",
        status: "pending",
    },
];

export const adminColumns = [
    {
        field: "id",
        headerName: "ID",
        width: 70
    },
    {
        field: "advokat",
        headerName: "Advokat",
        width: 230,
    },
    {
        field: "pib",
        headerName: "PIB",
        width: 100,
    },
    {
        field: "br",
        headerName: "Broj telefona",
        width: 200,
    },
    {
        field: "email",
        headerName: "Email",
        width: 230,
    },
    {
        field: "fromDate",
        headerName: "Početak",
        width: 150,
    },
    {
        field: "toDate",
        headerName: "Važi do",
        width: 150,
    },
    //{
    //    field: "status",
    //    headerName: "Status",
    //    width: 160,
    //    renderCell: (params) => {
    //        return (
    //            <div className={`cellWithStatus ${params.row.status}`}>
    //                {params.row.status}
    //            </div>
    //        );
    //    },
    //},
];

//temporary data
export const adminRows = [
    {
        id: 1,
        advokat: "Advokat Peta Petrovic",
        pib: "123123123",
        br: "0644420296",
        email: "emain12@gmail.com",
        fromDate: "05.10.2023",
        toDate: "15.12.2023",
    },
    {
        id: 2,
        advokat: "Advokat Neko Nekovic",
        pib: "321321321",
        br: "0644420296",
        email: "emain55@gmail.com",
        fromDate: "06.10.2023",
        toDate: "15.12.2023",
    },
    {
        id: 3,
        advokat: "Advokat Zika Zikic",
        pib: "555444666",
        br: "06423232344",
        email: "emain45@gmail.com",
        fromDate: "10.10.2023",
        toDate: "15.12.2023",
    },
    {
        id: 4,
        advokat: "Advokat NEKA FIRMA",
        pib: "123456789",
        br: "06412332112",
        email: "emain23@gmail.com",
        fromDate: "12.10.2023",
        toDate: "15.12.2023",
    },
    {
        id: 5,
        advokat: "AdvokatIme Imenkovic",
        pib: "111222333",
        br: "06411122233",
        email: "emain22@gmail.com",
        fromDate: "13.10.2023",
        toDate: "15.12.2023",
    },
    {
        id: 6,
        advokat: "Advokat Ime Imenkovic",
        pib: "222333444",
        br: "06422334455",
        email: "emaina1@gmail.com",
        fromDate: "15.10.2023",
        toDate: "15.12.2023",
    },
];
