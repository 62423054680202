import { Navigate } from 'react-router-dom';
import Home from "./layouts/home/Home";
import Login from "./views/login/Login";
import ResetPassword from "./views/resetPassword/ResetPassword";
import SmsCode from "./views/smsCode/SmsCode";
import AdvokatMain from "./layouts/advokat/AdvokatMain"
import PrivacyPolicy from "./views/privacyPolicy/PrivacyPolicy"
import TermsOfUse from "./views/termsOfUse/TermsOfUse"

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/resetPassword',
        element: <ResetPassword />
    },
    {
        path: '/uslovi',
        element: <TermsOfUse />
    },
    {
        path: '/pravila',
        element: <PrivacyPolicy />
    },
    {
        path: '/smsCode',
        element: <SmsCode />
    },
    {
        path: '/advokat/*',
        element: <AdvokatMain />
    }
    //{
    //    path: '/advokat/new',
    //    element: <NewCase />
    //},
    //{
    //    path: '/advokat/account',
    //    element: <AdvokatAccount />
    //},
    //{
    //    path: '/advokat/settings',
    //    element: <ContentSettingsAdvokat />
    //}
];

export default AppRoutes;
