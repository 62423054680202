import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import "./Navbar.css";
import {FaTwitter, FaInstagram, FaFacebook} from "react-icons/fa";
//import { IoMdRocket } from "react-icons/io";
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {

    const [navToggle, setNavToggle] = useState(false);
    const navHandler = () => {
        setNavToggle(prevData => !prevData);
    }

  return (
      <nav className='navbar w-100 flex'>
          <div className='container w-100'>
              <div className='navbar-content w-100'>
                  <div className='brand-and-toggler flex flex-between w-100'>
                      <HashLink smooth to="/#header" className='navbar-brand fs-26'>eDvokat</HashLink>
                      <div type="button" className={`hamburger-menu ${navToggle ? 'hamburger-menu-change' : ""}`} onClick={navHandler}>
                          <div className='bar-top'></div>
                          <div className='bar-middle'></div>
                          <div className='bar-bottom'></div>
                      </div>

                <div className={`navbar-collapse ${navToggle ? 'show-navbar-collapse' : ""}`}>
                    <div className='navbar-collapse-content'>
                              {/*<ul className='navbar-nav'>*/}
                        <ul className={`${navToggle ? 'navbar-nav' : 'navbar-social flex'}`}>
                            <li className='text-white'>
                                      <HashLink className='navbar-brand fs-26' smooth to="/#about">About</HashLink>
                            </li>
                            <li className='text-white'>
                                      <HashLink className='navbar-brand fs-26' to="/#features">Features</HashLink>
                            </li>
                            <li className='text-white'>
                                      <HashLink className='navbar-brand fs-26' to="/#portfolio">Portfolio</HashLink>
                            </li>
                            <li className='text-white'>
                                <HashLink className='navbar-brand fs-26' smooth to="/#contact">Contacts</HashLink>
                            </li>
                            <li className='text-white'>
                                <Link className='navbar-brand fs-26' to="/login">Login</Link >
                            </li>
                        </ul>
                        <ul className='navbar-social flex'>
                            <li className='text-white'>
                                <Link to = "" className='flex flex-center'><FaTwitter /></Link>
                            </li>
                            <li className='text-white'>
                                <Link to = "" className='flex flex-center'><FaInstagram /></Link>
                            </li>
                            <li className='text-white'>
                                <Link to = "" className='flex flex-center'><FaFacebook /></Link>
                            </li>
                        </ul>
                    </div>
                      </div>

                  </div>
            </div>
        </div>
    </nav>
  )
}

export default Navbar