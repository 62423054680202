import './AdvokatMain.css';
import Sidebar from '../../components/advokat/Sidebar/SidebarAdvokat';
import "../../components/advokat/Content/Content.css";
import ContentTop from '../../components/advokat/Content/ContentTop/ContentTop';
import { navigationLinksAdvokat } from '../../data/data';
import { Routes, Route } from 'react-router-dom';
//import Content from '../../components/advokat/Content/Content';
import { SidebarProvider } from '../../components/advokat/Content/Sidebar/Sidebar.jsx';
import advokatRoutes from "../../routes/advokat.jsx";

function AdvokatMain() {
    return (
        <>
            <SidebarProvider>
                <div className='advokatMain'>
                    <Sidebar />

                    <div className='main-content'>
                        <ContentTop notification="1"/>
                        <Routes>
                            {advokatRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                        {/*<div className="main-content-holder">*/}
                        {/*    <div className="content-grid-one">*/}
                        {/*        <Datatable />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {/*<Content />*/}
                </div>
            </SidebarProvider>
        </>
    )
}

export default AdvokatMain