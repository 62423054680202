import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppRoutes from './AppRoutes';

// new imports stop
import "bootstrap/dist/css/bootstrap.css";
//import "./assets/scss/paper-dashboard.scss";
import "./assets/demo/demo.css";
//import indexRoutes from "./routes/index.jsx";

//import Home from "./features/home/Home";

function App() {
    return (
            //<Routes>
            //    {indexRoutes.map((prop, key) => {
            //        return <Route path={prop.path} key={key} component={prop.component} />;
            //    })}
            //</Routes>
        <Routes>
            {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    );
}

export default App;
