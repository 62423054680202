import React, { Component } from "react";
import { Link } from "react-router-dom";
import './ResetPassword.css'

class ResetPassword extends Component {
    constructor() {
        super();

        this.state = {
            email: "",
            password: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let target = event.target;
        let value = target.type === "checkbox" ? target.checked : target.value;
        let name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        console.log("The form was submitted with the following data:");
        console.log(this.state);
    }

    render() {
        return (
            <div className="App1">
                <div className="appAside" />
                <div className="appForm">


                    <div className="formTitle">
                        <p className="formTitleLink"> Zaboravili ste Vašu lozinku? </p>
                    </div>
                    <p className="formText"> Unesite Vaš e-mail kako bi smo Vam poslali novu lozinku: </p>

                    <div className="formCenter">
                        <form className="formFields" onSubmit={this.handleSubmit}>
                            <div className="formField">
                                <label className="formFieldLabel" htmlFor="email">
                                    E-Mail adresa
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="formFieldInput"
                                    placeholder="Unesite Vaš e-mail"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="formField">
                                <button className="formFieldButton">Resetuj lozinku</button>{" "}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;
