import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {

    const navigate = useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault();

        navigate('/login');
    }

    return (
        <div className="App1">
            <div className="appAside" />
            <div className="appForm">


                <div className="formTitle">
                    <p className="formTitleLink"> Politika privatnosti </p>

                </div>
                <div className="formCenter">
                    <div className="formField">
                        <p>
                            Ova Politika privatnosti uređuje kako eDvokat doo
                            Beograd („eDvokat“) prikuplja, obrađuje i deli
                            podatke o Vama. Ova Politika privatnosti se odnosi
                            na podatke koje prikupljamo prilikom korišćenja naših
                            proizvoda i usluga („Usluge“). Posebno naglašavamo
                            da se ova Politika privatnosti ne odnosi na Vaš
                            pristup i korišćenje internet stranica trećih lica, usluga
                            ili platformi preko kojih pristupate našim Uslugama.
                            Savetujemo Vam da pregledate politike privatnosti tih
                            trećih lica radi dobijanja više podataka u vezi njihovih
                            praksi koje se odnose na privatnost.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            I IZMENE I DOPUNE POLITIKE PRIVATNOSTI
                        </label>
                        <p>
                            eDvokat može periodično vršiti izmene i dopune
                            Politike privatnosti. Obavestićemo Vas o svim
                            izmenama i dopunama Politike privatnosti, sa
                            naznakom datuma od kojeg izmene stupaju na
                            snagu. Savetujemo Vam da pregledate našu Politiku
                            privatnosti prilikom svakog pristupanja Uslugama,
                            kako bi bili obavešteni o našim praksama za obradu
                            podatka i kako bi zaštitili svoju privatnost.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            II PRIKUPLJANJE PODATAKA
                        </label>
                        <p>
                            Prilikom pristupanja Uslugama preko online platforme
                            („Platforma“), a u skladu sa pozitvnim propisima
                            Republike Srbije (“Zakon”), propisima EU koji
                            regulišu zaštitu podataka o ličnosti (“GDPR”) eDvokat
                            će prikupljati određene podatke o Vama, koji su
                            navedeni niže u ovoj Politici privatnosti.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            II.I Podaci koje nam pružate
                        </label>
                        <p>
                            eDvokat prikuplja podatke koje nam direktno pružate
                            kroz interakciju sa nama ili prilikom korišćenja
                            Usluga. Od sadržaja Vaše interakcije sa eDvokatom
                            zavisi koje konkretne podatke prikupljamo.
                            Korisnici platforme
                            Registrovani korisnik Platforme koji koristiti naše
                            Usluge („Korisnik“) dužan je da pruži određene
                            podatke o ličnosti, kako bi registrovao nalog, kao što
                            su: ime i prezime, korisničko ime (Username), imejl
                            adresa, broj telefona, mesto prebivališta/sedišta,
                            adresa prebivališta/sedišta, zanimanje, datum
                            rođenja, pol, broj advokatske legitimacije, matični broj
                            i poreski identifikacioni broj. Ovi podaci se prikupljaju
                            prilikom registracije sa ciljem:
                        </p>
                        <lu>
                            <li>
                                Da kreiramo Vaš nalog;
                            </li>
                            <li>
                                Da vodimo analitičku evidenciju o
                                korisnicima;
                            </li>
                            <li>
                                Da vodimo sintetičku evidenciju o
                                korisnicima;
                            </li>
                            <li>
                                Da vršimo statističke analize o korisnicima;
                            </li>
                            <li>
                                Da vam dostavimo odgovarajuće informacije
                                i upitnike koji su specifični za Vaše poslovne
                                interese.
                            </li>
                        </lu>
                        <p>
                            Tokom korišćenja naših usluga, pružaćete nam
                            podatke koje ćemo koristiti u cilju prilagođavanja
                            naših Usluga Vašim potrebama. Na primer, kako
                            bismo Vam pomogli da napravite odgovarajući model
                            računa i korisničke table, popunićete upitnike koji će
                            nam pomoći da Vam predložimo odgovarajuće
                            modele interfejsa. Od Vas može biti zahtevano da
                            nam kroz odgovore na niz pitanja o Vašim poslovnim
                            potrebama pružite podatke na osnovu kojih ćemo
                            moći da prilagodimo korisničku tablu u cilju izvršenja
                            Usluge. Naši zaposleni će imati uvid u Vaše
                            odgovore iz upitnika, kako biste dobili odgovarajuću
                            Uslugu u skladu sa Vašim potrebama.
                            Od Vas može biti zahtevano da iskažete mišljenje o
                            Uslugama eDvokata i njihovom kvalitetu. Vaša
                            iskazana mišljenja i odgovori će se koristiti u cilju
                            poboljšanja naših Usluga. U nekim slučajevima, o tim
                            podacima mogu biti upoznati i eksterni saradnici, ali
                            bez otkrivanja Vašeg identiteta, a u cilju poboljšanja
                            kvaliteta Usluga.
                            Osetljivi podaci o ličnosti i demografski podaci
                            Tokom korišćenja Usluga od Vas se načelno neće
                            zahtevati da pružite bilo kakve osetljive podatke o
                            ličnosti, kao što su finansijski ili demografski podaci.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            II.II Podaci koje automatski prikupljamo tokom
                            korišćenja Usluga
                        </label>
                        <p>
                            eDvokat ne prikuplja automatski podatke o ličnosti
                            tokom korišćenja Usluga.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            II.III Podaci koje prikupljamo preko „kolačića“
                        </label>
                        <p>
                            eDvokat ne prikuplja podatke o ličnosti preko
                            „kolačića“ (cookies). Savetujemo Vam da pregledate
                            politike privatnosti trećih lica, usluga ili platformi
                            preko kojih pristupate našim Uslugama radi dobijanja
                            više podataka u vezi njihovih praksi koje se odnose
                            na prikupljanje podataka o ličnosti preko „kolačića“.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            II.IV Podaci koje prikupljamo iz drugih izvora
                        </label>
                        <p>
                            eDvokat može prikupljati podatke iz drugih javno
                            dostupnih izvora i kombinovati ih sa podacima koje je
                            prikupio preko vršenja Usluge. Mogu se pojaviti
                            izolovani slučajevi kada prikupimo podatke o Vama
                            od strane trećih lica s kojima ste bili u interakciji
                            prilikom prijema Usluge, ukoliko se pojavio neki
                            problem u toku izvršenja Usluge, ili ukoliko ste
                            iskazali neslaganje ili zabrinutost u vezi sa izborom ili
                            ponašanjem trećih lica i ukoliko su trećim licima
                            neophodni dodatni podaci sa naše strane.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            III UPOTREBA PODATAKA
                        </label>
                        <p>
                            eDvocate koristi podatke koje je prikupio u cilju
                            obezbeđivanja, očuvanja i unapređenja Usluga, što
                            obuhvata i procenu kvaliteta Usluge.
                            Podaci koje prikupljamo od Korisnika mogu se
                            koristiti u cilju:
                        </p>
                        <lu>
                            <li>
                                Izvršenja usluga koje zahtevate,
                                procesuiranja transakcija i dostavljanja
                                obaveštenja;
                            </li>
                            <li>
                                Potvrđivanja Vašeg identiteta;
                            </li>
                            <li>
                                Odgovora na Vaša pitanja, komentare i
                                zahteve;
                            </li>
                            <li>
                                Komunikacije sa Vama o proizvodima,
                                uslugama, ponudama, promocijama,
                                nagradama i događajima koje nudimo mi, ili
                                treća lica, i dostavljanja obaveštenja i
                                novosti za koje smatramo da Vam mogu biti
                                interesantni;
                            </li>
                            <li>
                                Praćenja i analize trendova, korišćenja i
                                aktivnosti u vezi sa Uslugama;
                            </li>
                            <li>
                                Sprovođenja istraživanja, analiza i kreiranja
                                upitnika;
                            </li>
                            <li>
                                Personalizovanja i unapređenja Usluga radi
                                prilagođavanja profilu Korisnika i njegovim
                                interesima;
                            </li>
                            <li>
                                Kontaktiranja putem telefonskog poziva,
                                SMS poruka, email-a i drugih aplikacija za
                                komunikaciju;
                            </li>
                            <li>
                                Upotrebe u druge svrhe radi kojih je podatak
                                prikupljen.
                            </li>
                        </lu>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            IV DELJENJE PODATAKA
                        </label>
                        <p>
                            Kako bi Vam omogućili korišćenje usluge, sa našim
                            saradnicima i poslovnim partnerima ćemo deliti
                            podatke o Vama kao što su lični i kontakt podaci.
                        </p>
                        <p>
                            Podatke o Vama možemo deliti u skladu sa ovom
                            Politikom privatnosti u sledećim slučajevima:
                        </p>
                        <lu>
                            <li>
                                Sa svojim zaposlenima, konsultantima i
                                pružaocima usluga kojima je potreban
                                pristup tim podacima kako bi izvršili posao
                                po našem nalogu, a za to imaju
                                odgovarajući osnov i razlog;
                            </li>
                            <li>
                                Odgovorom na zahtev za pružanje
                                podataka, ako je takav zahtev u saglasnosti
                                za Zakonom ili drugim propisom, pod
                                uslovom da se takav zahtev ispostavlja u
                                zakonom regulisanom postupku, u skladu sa
                                bilo kojim pravom, pravilom ili propisom koje
                                se primenjuje na naš odnos;
                            </li>
                            <li>
                                Ako smatramo da Vaši postupci nisu u
                                skladu sa odredbama Uslova korišćenja
                                koje ste prihvatili, a u cilju zaštite prava koja
                                pripadaju nama, Vama ili trećim licima;
                            </li>
                            <li>
                                U slučaju pregovora sa drugim privrednim
                                subjektom, o spajanju sa pripajanjem,
                                prodaji naše imovine, pribavljanju
                                investicionog kapitala ili prenosu našeg
                                poslovanja, delimično ili u celosti;
                            </li>
                            <li>
                                Kada Vas tokom korišćenja Usluge
                                obavestimo da želimo da podelimo sa trećim
                                licima određene podatke koje ste nam
                                pružili, a Vi nam date saglasnost za to.
                            </li>
                        </lu>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            V ANALITIKA I OGLAŠAVANJE
                        </label>
                        <p>
                            Za potrebe pružanja naših Usluga, možemo
                            angažovati eksterne davaoce analitičkih usluga. Ta
                            lica za nas prate aktivnosti na Platformi kako bi nam
                            pomogli da identifikujemo polja primene naših Usluga
                            i unapredimo Vaše korisničko iskustvo. Ta lica uz
                            Vašu izričitu saglasnost mogu koristiti kolačiće
                            („cookies“), svetionike („web beacons“), identifikaciju
                            uređaja i druge tehnologije u cilju prikupljanja
                            podataka o Vašem korišćenju Usluga, uključujući
                            Vašu IP adresu, internet pretraživač, podatke o
                            korišćenju mobilne mreže, internet stranice koje ste
                            pregledali, vreme provedeno na internet stranicama
                            ili aplikacijama, linkove koje ste sledili i podatke o
                            komunikaciji. eDvokat i davaoci analitčkih usluga
                            obrađuju te podatke u cilju analiziranja podataka i
                            boljeg razumevanje Vaših aktivnosti na Platformi
                            tokom korišćenja naših Usluga.
                            Korisnik može u bilo kom trenutku, preko Platforme ili
                            putem obaveštenja eDvokatu, povući saglasnost za
                            korišćenje podataka u analitičke i marketinške svrhe.
                            Napominjemo da će Korisnik primati generičke
                            oglase bez obzira da li je povukao saglasnost za
                            korišćenje podataka u analitičke i marketinške svrhe.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            VI PRENOS PODATAKA U DRUGE DRŽAVE
                        </label>
                        <p>
                            Naše sedište je u Republici Srbiji i prikupljamo
                            podatke u skladu sa pravom Republike Srbije.
                            Pristupanjem Uslugama i njihovim korišćenjem ili
                            pružanjem podataka nama na drugi način,
                            saglašavate se sa obradom i deljenjem podataka u
                            Republici Srbiji. Moguće je da ista prava i obaveze
                            nisu uređene na identičan način kao u državi Vašeg
                            porekla.
                        </p>
                        <p>
                            eDvocate obezbeđuje da primalac Vaših podataka o
                            ličnosti obezbeđuje primeren nivo zaštite podataka,
                            npr. putem obavezujućeg ugovora između eDvocate i
                            primaoca podataka, koji sadrži uobičajene ugovorne
                            odredbe o prenosu podataka u skladu sa odlukom
                            Evropske komisije (videti čl. 46 GDPR).
                        </p>
                        <p>
                            Ukoliko imate nerešena pitanja o privatnosti ili
                            upotrebi podataka, na koje nismo odgovorili na
                            zadovoljavajući način, molimo Vas da kontaktirate
                            našeg pravnog savetnika na email:
                            office@edvocate.com. Komunikacija sa našim
                            pravnim savetnikom se ne naplaćuje. U bilo kom
                            trenutku imate pravo da predložite rešenje spora
                            medijacijom, u skladu sa Zakonom. eDvokat je u
                            skladu sa Zakonom subjekat nad kojim Poverenik za
                            informacije od javnog značaja i zaštitu podataka o
                            ličnosti Republike Srbije ima pravo nadzora. U
                            slučaju da delimo EU podatke sa pružaocima usluga
                            koji su treća lica, a koji obrađuju podatke u naše ime i
                            za naš račun, u slučaju povrede odredaba GDPR mi
                            odgovaramo za obradu podataka od strane tih trećih
                            lica, osim ukoliko dokažemo da nismo odgovorni za
                            događaj koji je prouzrokovao štetu.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            VII ČUVANJE PODATAKA
                        </label>
                        <p>
                            Podatke koje smo prikupili o Vama čuvamo onoliko
                            koliko je neophodno radi ostvarenja svrhe zbog koje
                            su prikupljeni, ili iz drugih legitimnih poslovnih
                            razloga, uključujući izvršenje naših zakonskih i
                            regulatornih obaveza.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            VIII PRAVO OPCIJE
                        </label>
                        <label className="formTitle">
                            VIII.I Vaši podaci
                        </label>
                        <p>
                            Imate pravo da osvežite, ispravite ili zahtevate
                            brisanje podatke o Vama u bilo koje vreme putem
                            obraćanja eDvokatu na imejl: office@edvocate.com.
                            Napominjemo da možemo zadržati određene
                            podatke u skladu sa Zakonom, ili ukoliko to zahtevaju
                            naši legitimni poslovni interesi. Takođe, tokom
                            određenog perioda možemo zadržati sačuvane i
                            arhivirane podatke o Vama.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            VIII.II Marketinška komunikacija
                        </label>
                        <p>
                            Možete odabrati da ne primate naše marketinške i
                            promotivne poruke, na način što ćete pratiti uputstva
                            za odjavu iz tih obaveštenja ili upućivanjem imejla na
                            adresu: office@edvocate.com. Ako ste se odjavili, i
                            dalje možete dobijati naša obaveštenja koja nisu
                            marketinške i promotivne prirode, kao što su
                            obaveštenja o Vašem korisničkom nalogu ili
                            obaveštenja o našem tekućem poslovnom odnosu.
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            IX SRPSKI KORISNICI
                        </label>
                        <p>
                            Ako pristupate Platformi ili koristite naše Usluge sa
                            teritorije Republike Srbije, raspolažete svojim
                            podacima o ličnosti u skladu sa važećom verzijom
                            Zakona o zaštiti podataka o ličnosti („Sl. glasnik RS“,
                            br. 87/2018) uključujući:
                        </p>
                        <lu>
                            <li>
                                Pravo da budete upoznati koje podatke o
                                ličnosti prikupljamo, obrađujemo i delimo;
                            </li>
                            <li>
                                Pravo da opozovete pristanak na obradu
                                podataka o ličnosti;
                            </li>
                            <li>
                                Pravo da podaci o ličnosti budu izbrisani (uz
                                Zakonom određene izuzetke)...
                            </li>
                        </lu>
                        <p>
                            eDvokat ne prodaje Vaše podatke, ali ih može deliti u
                            skladu sa odredbama ove Politike privatnosti sa
                            zaposlenima, konsultantima i drugim pružaocima
                            usluga koji obrađuju podatke u naše ime i za naš
                            račun, a sa kojima eDvokat ima ugovorom regulisani
                            odnos u skladu sa Zakonom. Navedena lica imaju
                            pravo na obradu i čuvanje Vaših podataka samo u
                            svrhu izvršenja usluga eDvokatu.
                        </p>
                        <p>
                            Ukoliko želite da iskoristite bilo koje pravo koje vam
                            pripada u vezi sa podacima o ličnosti, u skladu sa
                            Zakonom, možete se obratiti pismeno, telefonom ili
                            mejlom na sledeće adrese:
                            eDvokat doo Beograd
                            Adresa: ulica Dr Nedeljka Ercegovca br. 19/III/5
                            Poštanski broj i mesto: 11082 Beograd-Zemun
                            Tel.: +381642491922
                            Email: office@edvocate.com
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            X EU KORISNICI
                        </label>
                        <p>
                            Ako pristupate Platformi ili koristite naše Usluge sa
                            teritorije zemalja Evropske unije, imate određena
                            prava koja su Vam garantovana u skladu sa važećom
                            verzijom Opšte uredbe o zaštiti podataka Evropske
                            komisije (EU 2016/769) (GDPR).
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            X.I Pravni osnov za obradu
                        </label>
                        <p>
                            Obrada Vaših podataka o ličnosti će se vršiti
                            isključivo u sledećim situacijama:
                        </p>
                        <lu>
                            <li>
                                Dali ste nam pristanak na obradu Vaših
                                podataka o ličnosti;
                            </li>
                            <li>
                                Vaši podaci su nam neophodni za izvršenje
                                Usluga na koje smo se obavezali Vama ili
                                drugom licu koje snosi naknadu za Vaše
                                korišćenje Usluga, za šta ste dali saglasnost
                                tim licima;
                            </li>
                            <li>
                                Imamo legitimni interes na obradu. Na
                                primer, obrađujemo Vaše podatke kako
                                bismo izvršili Uslugu, kako bismo Vas
                                obavestili o promenama Usluga i kako
                                bismo ih unapredili.
                            </li>
                        </lu>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            X.II Zahtevi u vezi obrade
                        </label>
                        <p>
                            Imate pravo na pristup Vašim Podacima o ličnosti
                            koje smo prikupili, kao i da zahtevate njihovu
                            ispravku, brisanje ili povraćaj. Takođe imate pravo da
                            zahtevate da ograničimo obradu podataka na
                            određeni način. Zahtev možete uputiti preko
                            Platforme, sa Vašeg korisničkog naloga, ili nam se
                            možete obratiti pismeno, telefonom ili mejlom na
                            sledeće adrese:
                            eDvokat doo Beograd
                            Adresa: ulica Dr Nedeljka Ercegovca br. 19/III/5
                            Poštanski broj i mesto: 11082 Beograd-Zemun
                            Tel.: +381642491922
                            Email: office@edvocate.com
                        </p>
                    </div>
                    <div className="formField">
                        <label className="formTitle">
                            X.III Pitanja, prigovori i žalbe
                        </label>
                        <p>
                            Ako ste zabrinuti u vezi naše obrade Vaših podataka
                            o ličnosti, ili imate pitanja koja nismo mogli da rešimo
                            u međusobnoj komunikaciji, imate pravo da uložite
                            prigovor ili žalbu organu za zaštitu podataka o ličnosti
                            sa teritorije EU na kojoj se nalazite. Kontakt podaci
                            lokalnog organa nadležnog za zaštitu podataka o
                            ličnosti možete naći na linku: 
                        </p>
                        <Link className="formFieldLink" to="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm">
                            http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm
                        </Link>
                        
                    </div>
                </div>
                <div className="buttonOnCenter">
                    <form className="formFields" onSubmit={handleSubmit}>
                        <button className="formFieldButton">Prihvatam</button>
                    </form>
                </div>
            </div>

        </div>
    );
}
export default PrivacyPolicy;